import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { ResultCraft } from "../../components";
import { itemType, useChickens, useCraft, useItems } from "../../hooks";
import { CraftPropsType } from "../../pages";
import { CustomChooseItem } from "../CustomChooseItem";

const getResult = (selectedItem: {
  [key: number]: { [key: string]: string };
}) => {
  if (
    selectedItem[1].weapon &&
    (selectedItem[2].rooster || selectedItem[2].hen)
  )
    return "Weapon";
  else if (
    selectedItem[1].armor &&
    (selectedItem[2].rooster || selectedItem[2].hen)
  )
    return "Armor";
  else if (selectedItem[1].hen && selectedItem[2].hen) return "Armor";
  else if (selectedItem[1].rooster && selectedItem[2].rooster) return "Weapon";
  else if (selectedItem[1].hen && selectedItem[2].rooster) return "Artefact";
  else if (selectedItem[1].rooster && selectedItem[2].hen) return "Artefact";
  else return "?";
};

export const Sacrifice: React.FC<CraftPropsType> = ({ setCraftedItem }) => {
  const { items, getItemStats, getItems } = useItems();
  const { roosters, hens, getUserChickens } = useChickens();
  const { mixSacrificeChicken, mixDoubleSacrifice } = useCraft();

  const [selectedItem, setSelectedItem] = useState<{
    [key: number]: { [key: string]: string };
  }>({
    1: {
      weapon: "",
      armor: "",
      rooster: "",
      hen: "",
    },
    2: {
      rooster: "",
      hen: "",
    },
  });

  const handleSelectItem = (amount: number) => (type: string, id: string) => {
    setSelectedItem((state) => ({
      ...state,
      [amount]: {
        weapon: "",
        armor: "",
        rooster: "",
        hen: "",
      },
    }));
    if (selectedItem[amount][type] === id)
      return setSelectedItem((state) => ({
        ...state,
        [amount]: {
          ...state[amount],
          [type]: "",
        },
      }));
    setSelectedItem((state) => ({
      ...state,
      [amount]: {
        ...state[amount],
        [type]: id,
      },
    }));
  };

  const handleCraft = async () => {
    let id;
    if (selectedItem[1].weapon || selectedItem[1].armor) {
      await toast.promise(
        () =>
          mixSacrificeChicken(
            selectedItem[1].weapon || selectedItem[1].armor,
            selectedItem[2].rooster || selectedItem[2].hen
          ),
        {
          pending: "Craft is pending",
          success: "Craft resolved 👌",
          error: "Craft rejected 🤯",
        }
      );
      id = selectedItem[1].weapon || selectedItem[1].armor;
    } else {
      id = await mixDoubleSacrifice(
        selectedItem[1].rooster || selectedItem[1].hen,
        selectedItem[2].rooster || selectedItem[2].hen
      );
    }

    const data = await getItemStats(id);

    setCraftedItem({
      id,
      type: itemType[data[0]],
      selector: data[1],
      bonus: data[2],
      zodiac: data[3],
      requiredWins: data[4],
      vampiric: data[5],
      isStaked: false,
    });
    await getItems();
    await getUserChickens();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "28px",
      }}
    >
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "20px 0px",
          background: "rgba(217, 217, 217, 0.05)",
          width: "30%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Slot 1
        </Typography>
        <CustomChooseItem
          label={["Weapon", "Armor", "Rooster", "Hen"]}
          handleSelectItem={handleSelectItem(1)}
          tabsContent={[
            {
              type: "weapon",
              items: items
                .filter((i) => i.type === "Weapon")
                .filter((i) => !i.isStaked)
                .filter((i) => i.id !== selectedItem[2].weapon),
              selected: selectedItem[1].weapon,
            },
            {
              type: "armor",
              items: items
                .filter((i) => i.type === "Armor")
                .filter((i) => !i.isStaked)
                .filter((i) => i.id !== selectedItem[2].armor),
              selected: selectedItem[1].armor,
            },
            {
              type: "rooster",
              items: roosters
                .filter((i) => i.id !== selectedItem[2].rooster)
                .filter((i) => !i.isStaked),
              selected: selectedItem[1].rooster,
            },
            {
              type: "hen",
              items: hens
                .filter((i) => i.id !== selectedItem[2].hen)
                .filter((i) => !i.isStaked),
              selected: selectedItem[1].hen,
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "20px 0px",
          background: "rgba(217, 217, 217, 0.05)",
          width: "30%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Slot 2
        </Typography>
        <CustomChooseItem
          label={["Rooster", "Hen"]}
          handleSelectItem={handleSelectItem(2)}
          tabsContent={[
            {
              type: "rooster",
              items: roosters
                .filter((i) => i.id !== selectedItem[1].rooster)
                .filter((i) => !i.isStaked),
              selected: selectedItem[2].rooster,
            },
            {
              type: "hen",
              items: hens
                .filter((i) => i.id !== selectedItem[1].hen)
                .filter((i) => !i.isStaked),
              selected: selectedItem[2].hen,
            },
          ]}
        />
      </Box>
      <ResultCraft
        result={getResult(selectedItem)}
        disabled={
          Object.values(selectedItem[1]).find((i) => i) &&
          Object.values(selectedItem[2]).find((i) => i)
            ? false
            : true
        }
        onClick={handleCraft}
      />
    </Box>
  );
};
