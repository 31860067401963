import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Typography } from "@mui/material";
import LogoImg from "../../assets/img/logo.png";
import RoosterCoin from "../../assets/img/roosterCoin.png";
import MaticLogo from "../../assets/img/matic.png";
import NetworkStatus from "../../assets/img/networkStatus.png";
import { useMetaMask } from "metamask-react";
import { useMemo } from "react";
import { useBalanceContext } from "../../context";

export const Header = () => {
  const { deposit, matic } = useBalanceContext();
  const { account } = useMetaMask();

  const contentUserName = useMemo(() => {
    if (!account) return "";
    return account.slice(0, 4) + ".." + account.slice(-4);
  }, [account]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 12px",
        backgroundColor: "#90CAF9",
        position: "fixed",
        width: "100%",
        height: "50px",
        zIndex: "10",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={LogoImg}
          alt="rooster"
          style={{
            width: "35px",
            objectFit: "cover",
          }}
        />
        <Typography
          sx={{
            color: "#020202",
            fontWeight: "700",
            marginLeft: "8px",
            textTransform: "uppercase",
          }}
        >
          Red hot Cockfights
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            padding: "0px 20px",
            borderLeft: "1px solid #000000",
            borderRight: "1px solid #000000",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={RoosterCoin}
              alt="rooster"
              style={{
                width: "35px",
                objectFit: "cover",
              }}
            />
            <Typography sx={{ color: "#020202", marginLeft: "8px" }}>
              {Math.floor(deposit * 100) / 100} RHC
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginLeft: "40px" }}
          >
            <img
              src={MaticLogo}
              alt="rooster"
              style={{
                width: "35px",
                objectFit: "cover",
              }}
            />
            <Typography sx={{ color: "#020202", marginLeft: "8px" }}>
              {matic.toFixed(2)} MATIC
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "15px",
            borderRight: "1px solid #000000",
          }}
        >
          <img
            src={NetworkStatus}
            alt="rooster"
            style={{
              width: "20px",
              objectFit: "cover",
            }}
          />
          <Typography
            sx={{ color: "#020202", margin: "0px 8px", fontSize: "14px" }}
          >
            Polygon Testnet
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
          }}
        >
          <Typography
            sx={{
              color: "#020202",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "8px",
            }}
          >
            {contentUserName}
          </Typography>
          <AccountCircleIcon sx={{ color: "#020202" }} />
        </Box>
      </Box>
    </Box>
  );
};
