// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotAuthContainers_wrapper__UZdxO {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.NotAuthContainers_content__BEh\\+u {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.NotAuthContainers_header__8LfU- {\n    font-style: normal;\n    font-weight: 500;\n    font-size: 20px;\n    line-height: 160%;\n    letter-spacing: 0.15px;\n}\n\n.NotAuthContainers_img__hbt5Q {\n    width: 300px;\n    height: 300px;\n    margin-top: 52px;\n}", "",{"version":3,"sources":["webpack://./src/containers/NotAuthContainers/NotAuthContainers.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".wrapper {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.header {\n    font-style: normal;\n    font-weight: 500;\n    font-size: 20px;\n    line-height: 160%;\n    letter-spacing: 0.15px;\n}\n\n.img {\n    width: 300px;\n    height: 300px;\n    margin-top: 52px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "NotAuthContainers_wrapper__UZdxO",
	"content": "NotAuthContainers_content__BEh+u",
	"header": "NotAuthContainers_header__8LfU-",
	"img": "NotAuthContainers_img__hbt5Q"
};
export default ___CSS_LOADER_EXPORT___;
