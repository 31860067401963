import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useChickens, useEggs, useItems } from '../../hooks';
import { SelectBreedingPair } from '../../containers';

export type PairType = {
  hen: string;
  rooster: string;
  item?: string;
  id?: string;
  createTime?: string;
};

export const Breeding = () => {
  const {
    hens,
    roosters,
    getUserHenHouses,
    stakeHenHouse,
    claimEggs,
    unstakeHenHouse,
  } = useChickens();
  const { items } = useItems();
  const { getEggStats } = useEggs();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedPairs, setSelectedPairs] = useState<{
    [key: string]: PairType;
  }>({
    1: {} as PairType,
    2: {} as PairType,
    3: {} as PairType,
  });

  const getHenHouses = async () => {
    setIsLoading(true);
    const res = await getUserHenHouses();

    // eslint-disable-next-line array-callback-return
    res.map((i: string[], index) => {
      setSelectedPairs((state) => ({
        ...state,
        [index + 1]: {
          id: i[0],
          rooster: i[1],
          hen: i[2],
          item: i[3] === '0' ? undefined : i[3],
          createTime: i[4],
        },
      }));
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getHenHouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography
        sx={{ fontSize: '20px', marginLeft: '20px', marginTop: '32px' }}
      >
        Breeding
      </Typography>
      {!isLoading && (
          <>
            <SelectBreedingPair
              hens={hens.filter(
                (i) =>
                  i.id !== selectedPairs[2].hen && i.id !== selectedPairs[3].hen
              )}
              amount={1}
              items={items.filter(
                (i) =>
                  i.id !== selectedPairs[2].item &&
                  i.id !== selectedPairs[3].item
              )}
              roosters={roosters.filter(
                (i) =>
                  i.id !== selectedPairs[2].rooster &&
                  i.id !== selectedPairs[3].rooster
              )}
              selectedPairs={selectedPairs}
              setSelectedPairs={setSelectedPairs}
              stakeHenHouse={stakeHenHouse}
              getUserHenHouses={getUserHenHouses}
              unstakeHenHouse={unstakeHenHouse}
              claimEggs={claimEggs}
              getEggStats={getEggStats}
            />
            <SelectBreedingPair
              hens={hens.filter(
                (i) =>
                  i.id !== selectedPairs[1].hen && i.id !== selectedPairs[3].hen
              )}
              amount={2}
              items={items.filter(
                (i) =>
                  i.id !== selectedPairs[1].item &&
                  i.id !== selectedPairs[3].item
              )}
              roosters={roosters.filter(
                (i) =>
                  i.id !== selectedPairs[1].rooster &&
                  i.id !== selectedPairs[3].rooster
              )}
              selectedPairs={selectedPairs}
              setSelectedPairs={setSelectedPairs}
              stakeHenHouse={stakeHenHouse}
              getUserHenHouses={getUserHenHouses}
              unstakeHenHouse={unstakeHenHouse}
              claimEggs={claimEggs}
              getEggStats={getEggStats}
            />
            <SelectBreedingPair
              hens={hens.filter(
                (i) =>
                  i.id !== selectedPairs[1].hen && i.id !== selectedPairs[2].hen
              )}
              amount={3}
              items={items.filter(
                (i) =>
                  i.id !== selectedPairs[1].item &&
                  i.id !== selectedPairs[2].item
              )}
              roosters={roosters.filter(
                (i) =>
                  i.id !== selectedPairs[1].rooster &&
                  i.id !== selectedPairs[2].rooster
              )}
              selectedPairs={selectedPairs}
              setSelectedPairs={setSelectedPairs}
              stakeHenHouse={stakeHenHouse}
              getUserHenHouses={getUserHenHouses}
              unstakeHenHouse={unstakeHenHouse}
              claimEggs={claimEggs}
              getEggStats={getEggStats}
            />
          </>
        )}
    </Box>
  );
};
