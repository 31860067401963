import { Box, Typography } from "@mui/material";
import SpellImg from "../../assets/img/spell.png";
import { getSpellValue } from "../../helpers";
import { Spells } from "../../hooks";

type SpellCardPropsType = {
  spell: Spells;
  type?: "spells" | "battle";
};

export const SpellCard: React.FC<SpellCardPropsType> = ({
  spell,
  type = "spells",
}) => {
  const data = getSpellValue(Number(spell.selector));

  return (
    <Box sx={{ display: "flex", height: type === "spells" ? "220px" : "" }}>
      <img
        src={SpellImg}
        alt="spell"
        style={{
          width: type === "spells" ? "187px" : "120px",
          height: type === "spells" ? "192px" : "120px",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          borderLeft: "1px solid #333333",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: type === "battle" ? "16px" : "18px",
            }}
          >
            {data?.name}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: type === "battle" ? "12px" : "18px",
            textAlign: "left",
            paddingLeft: "10px",
          }}
        >
          {data?.bonus.replace("<value>", spell.bonus)}
        </Typography>

        <Box>
          <Typography
            sx={{  fontSize: type === "battle" ? "12px" : "18px", textAlign: "left", paddingLeft: "10px" }}
          >
            Required wins: {spell.requiredWins}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
