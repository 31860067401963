import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useLinkClickHandler } from 'react-router-dom';
import { EggCard, ReviewCardCenterChild } from '../../components';
import { useEggs } from '../../hooks';

const gridStyles = {
  paddingBottom: 2,
  paddingRight: 2,
  marginTop: 2,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
};

export const Eggs = () => {
  const { eggs, isLoading } = useEggs();

  const goToMarketpalce = useLinkClickHandler('/marketplace') as () => void;

  return (
    <Box>
      <Typography
        sx={{ fontSize: '20px', marginLeft: '20px', marginTop: '32px' }}
      >
        Eggs | Total: {eggs.length}
      </Typography>
      {isLoading ? (
        <></>
      ) : eggs.length ? (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          columns={16}
          sx={gridStyles}
        >
          {eggs.map((i) => (
            <Grid item xs={5} key={i.id}>
              <ReviewCardCenterChild
                id={i.id}
                title={`Egg ID #${i.id}`}
                titleBtn="Hatch"
                link="/hatching"
              >
                <EggCard egg={i} />
              </ReviewCardCenterChild>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
          }}
        >
          <Button
            sx={{ width: '276px', fontSize: '14px' }}
            variant="contained"
            onClick={goToMarketpalce}
          >
            buy eggs in marketplace
          </Button>
        </Box>
      )}
    </Box>
  );
};
