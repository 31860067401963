import { Box, Modal, Tab, Tabs, Typography } from '@mui/material';
import { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import {
  CraftedItemModal,
  ItemsCraft,
  MintSprigCraft,
  Sacrifice,
} from '../../containers';
import { Item } from '../../hooks';

export type CraftPropsType = {
  setCraftedItem: Dispatch<SetStateAction<null | Item>>;
};

const descriptions = {
  0: 'In item craft, you can merge two items to receive one better. Select one item in the first slot and another one in the second. After pushing the "craft" button second item will disappear and the first one will get better parameters.',
  1: 'In sacrifice craft, you can merge two live hens or roosters, or hen and rooster. And you can merge live hen/rooster and item. In case when you merge the item and live hen/rooster, then hen/rooster will disappear and the item will get better parameters. If you will merge two live hens or roosters, or hen and rooster the result will be: rooster + rooster = weapon. hen + hen = armor. hen + rooster = artefact. Roosters and hens always will disappear after sacrifice craft.',
  2: 'In the "mint sprig" craft you can merge items with mint sprig. It`s a special artifact that improves your weapon. After pushing the "craft" button mint sprig will disappear and the first one will get better parameters.',
};

export const WitchcraftDoctor = () => {
  const [value, setValue] = useState(0);
  const [craftedItem, setCraftedItem] = useState<null | Item>(null);

  const handleChange = (_: SyntheticEvent, newValue: number) =>
    setValue(newValue);

  const handleClose = () => setCraftedItem(null);

  return (
    <>
      <Box sx={{ marginLeft: '20px', marginRight: '20px' }}>
        <Typography sx={{ fontSize: '20px', marginTop: '32px' }}>
          Witchcraft woodoo doctor
        </Typography>
        <Box sx={{ width: '555px', marginTop: '32px' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            aria-label="disabled tabs example"
            sx={{ background: '#2F373F' }}
          >
            <Tab label="Items craft" sx={{ padding: '0px 52px' }} />
            <Tab label="Sacrifice" sx={{ padding: '0px 52px' }} />
            <Tab label="Mint sprig" sx={{ padding: '0px 52px' }} />
          </Tabs>
        </Box>
        <Box sx={{marginBottom: '10px'}}>
          {value === 0 && <ItemsCraft setCraftedItem={setCraftedItem} />}
          {value === 1 && <Sacrifice setCraftedItem={setCraftedItem} />}
          {value === 2 && <MintSprigCraft setCraftedItem={setCraftedItem} />}
          <Typography
            sx={{ width: '30%', fontSize: '12px', marginTop: '24px' }}
          >
            {descriptions[value]}
          </Typography>
        </Box>
      </Box>
      <Modal
        open={Boolean(craftedItem)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="modal-modal-title">
          {craftedItem && (
            <CraftedItemModal item={craftedItem} handleClose={handleClose} />
          )}
        </Box>
      </Modal>
    </>
  );
};
