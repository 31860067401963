import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction, useState } from "react";
import {
  BurnSvg,
  ColorButton,
  HenCard,
  ItemCard,
  ReviewCardCenterChild,
  RoosterCard,
  SingleSelectCard,
  SpellCard,
} from "../../components";
import { useBalanceContext } from "../../context";
import { ChickenType, Item, Spells } from "../../hooks";
import { modalStyle } from "../ClaimEggsModal";

type BurnModalPropsType = {
  items: ChickenType[] | Item[] | Spells[];
  type: "Rooster" | "Hen" | "Item" | "Spell";
  closeModal: () => void;
  burnItems: (ids: string[]) => Promise<any>;
  changeItemsArray:
    | Dispatch<SetStateAction<ChickenType[]>>
    | Dispatch<SetStateAction<Item[]>>
    | Dispatch<SetStateAction<Spells[]>>;
};

export const BurnModal: React.FC<BurnModalPropsType> = ({
  type,
  items,
  burnItems,
  closeModal,
  changeItemsArray,
}) => {
  const { getDeposit, getWalletBalance } = useBalanceContext();

  const [showed, setShowed] = useState<number>(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [isResultModal, setIsResultModal] = useState<boolean>(false);

  const decrement = () => setShowed((state) => state - 1);
  const increment = () => setShowed((state) => state + 1);

  const handleSelect = (id: string) => () => {
    if (selected.find((i) => i === items[showed].id)) {
      const newArr = selected.filter((i) => i !== id);
      setSelected(newArr);
      return;
    }
    setSelected((state) => [...state, id]);
  };

  const handleBurn = async () => {
    const res = await burnItems(selected);
    if (!res) return;

    setIsResultModal(true);

    const arrItens: any = items
    const arr = arrItens.filter((item) => selected.indexOf(item.id) === -1);
    changeItemsArray(arr);

    await getDeposit();
    await getWalletBalance();
  };

  return (
    <Box
      sx={{
        ...modalStyle,
        display: "flex",
        width: !isResultModal ? "759px" : "485px",
      }}
    >
      {!isResultModal ? (
        <>
          <SingleSelectCard
            decrement={decrement}
            increment={increment}
            showedItem={showed}
            itemsLength={items.length}
            childrenWidth={200}
            children={
              items[showed] ? (
                <ReviewCardCenterChild
                  title={`${type} ID #${items[showed].id}`}
                  secondBtnTitle="Select"
                  secondButtonHandler={handleSelect(items[showed].id)}
                  isSelected={Boolean(
                    selected.find((i) => i === items[showed].id)
                  )}
                >
                  {type === "Rooster" && (
                    <RoosterCard
                      rooster={items[showed] as ChickenType}
                      type="arena"
                    />
                  )}

                  {type === "Hen" && (
                    <HenCard hen={items[showed] as ChickenType} type="breed" />
                  )}
                  {type === "Item" && (
                    <ItemCard item={items[showed] as Item} type="arena" />
                  )}
                  {type === "Spell" && (
                    <SpellCard spell={items[showed] as Spells} type="battle" />
                  )}
                </ReviewCardCenterChild>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "200px",
                    border: "1px dashed rgb(204, 204, 204)",
                    borderRadius: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  Empty
                </Box>
              )
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ textTransform: "uppercase", textAlign: "center" }}
            >
              Please, select {`${type}s`} which you want to burn
            </Typography>
            {selected.length ? (
              <Box>
                <Typography
                  sx={{ textTransform: "uppercase", textAlign: "center" }}
                >
                  Selected {selected.length}{" "}
                  {selected.length === 1 ? type : `${type}s`}
                </Typography>
                <Typography
                  sx={{ textTransform: "uppercase", textAlign: "center" }}
                >
                  your reward: {selected.length * 0.5} RHC
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <ColorButton disabled={!selected.length} onClick={handleBurn}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "35px",
                  }}
                >
                  <Typography
                    sx={{
                      borderRight: "1.5px solid #CCCCCC",
                      paddingRight: "6px",
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "15px",
                    }}
                  >
                    burn selected
                  </Typography>
                  <Box
                    sx={{
                      paddingLeft: "6px",
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BurnSvg />
                  </Box>
                </Box>
              </ColorButton>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  border: "1.5px solid #CCCCCC",
                  height: "35px",
                  color: "#CCCCCC",
                }}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ textTransform: "uppercase", textAlign: "center" }}>
            {selected.length} {selected.length === 1 ? type : `${type}s`} burned{" "}
          </Typography>
          <Typography sx={{ textTransform: "uppercase", textAlign: "center" }}>
            {selected.length * 0.5} RHC received
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "10px",
              border: "1.5px solid #CCCCCC",
              height: "35px",
              color: "#CCCCCC",
              marginTop: "50px",
            }}
            onClick={closeModal}
          >
            OK
          </Button>
        </Box>
      )}
    </Box>
  );
};
