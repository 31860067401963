// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_wrapper__Mru9Z{\n    display: flex;\n    flex-direction: column;\n}\n.App_content__wwONY{\n  display: flex;\n}\n.App_centerContent__mnCWp{\n  width: 100%;\n  margin-left: 250px;\n  margin-top: 45px;\n}\n\n.App_unavailableWrapper__4VZuO{\n   width: 100%;\n   height: 100vh;\n   display: flex;\n   justify-content: center;\n   align-items: center;\n}\n\n.App_unavailableContent__SfcX0 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 32px;\n}\n\n.App_unavailableContent__SfcX0 span{\n  margin: 0px 3px;\n}\n\n.App_unavailableContent__SfcX0 span svg{\n  width: 48px;\n  height: 48px\n}", "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;GACG,WAAW;GACX,aAAa;GACb,aAAa;GACb,uBAAuB;GACvB,mBAAmB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX;AACF","sourcesContent":[".wrapper{\n    display: flex;\n    flex-direction: column;\n}\n.content{\n  display: flex;\n}\n.centerContent{\n  width: 100%;\n  margin-left: 250px;\n  margin-top: 45px;\n}\n\n.unavailableWrapper{\n   width: 100%;\n   height: 100vh;\n   display: flex;\n   justify-content: center;\n   align-items: center;\n}\n\n.unavailableContent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 32px;\n}\n\n.unavailableContent span{\n  margin: 0px 3px;\n}\n\n.unavailableContent span svg{\n  width: 48px;\n  height: 48px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "App_wrapper__Mru9Z",
	"content": "App_content__wwONY",
	"centerContent": "App_centerContent__mnCWp",
	"unavailableWrapper": "App_unavailableWrapper__4VZuO",
	"unavailableContent": "App_unavailableContent__SfcX0"
};
export default ___CSS_LOADER_EXPORT___;
