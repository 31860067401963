import ReactDOM from "react-dom/client";
import { MetaMaskProvider } from "metamask-react";

import { StyledEngineProvider } from "@mui/material/styles";
import { App } from "./App";
import "./styles/index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StyledEngineProvider injectFirst>
    <MetaMaskProvider>
      <App />
    </MetaMaskProvider>
  </StyledEngineProvider>
);
