import styled from "@emotion/styled";
import { Slider, SliderThumb } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FavouriteIcon } from "../Svg";

const CustomSlider = styled(Slider)(() => ({
  color: "#C51010",
  height: 3,
  padding: "13px 0",
  borderRadius: "1px",
  cursor: "auto",
  "& .MuiSlider-thumb": {
    height: 56,
    width: 56,
    backgroundColor: "transparent",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-track": {
    height: "31px",
    borderRadius: "1px",
    border: "1px solid #CCCCCC",
  },
  "& .MuiSlider-rail": {
    color: "transparent",
    opacity: 1,
    height: "31px",
    border: "1px solid #CCCCCC",
  },
}));

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {}
function ThumbComponent(props: ThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <FavouriteIcon />
    </SliderThumb>
  );
}

type FavouriteSliderPropsType = {
  maxValue: number;
  value: number;
};

export const FavouriteSlider: React.FC<FavouriteSliderPropsType> = ({
  maxValue,
  value,
}) => {
  const [range, setRange] = useState<number>(value);

  useEffect(() => {
    setRange(value);
  }, [value]);

  return (
    <CustomSlider
      aria-label="Volume"
      value={range}
      max={maxValue}
      min={0}
      components={{
        Thumb: ThumbComponent,
      }}
      sx={{ height: "20px", opacity: "1" }}
    />
  );
};
