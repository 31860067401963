import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { ResultCraft } from "../../components";
import { itemType, useCraft, useItems } from "../../hooks";
import { CraftPropsType } from "../../pages";
import { CustomChooseItem } from "../CustomChooseItem";

const getResult = (selectedItem: {
  [key: number]: { [key: string]: string };
}) => {
  if (selectedItem[1].weapon && selectedItem[2].artefact) return "Weapon";
  else if (selectedItem[1].armor && selectedItem[2].artefact) return "Armor";
  else if (selectedItem[1].artefact && selectedItem[2].artefact)
    return "Artefact";
  else return "?";
};

export const MintSprigCraft: React.FC<CraftPropsType> = ({
  setCraftedItem,
}) => {
  const { items, getItemStats, getItems } = useItems();
  const { mixWithMintSprig } = useCraft();

  const [selectedItem, setSelectedItem] = useState<{
    [key: number]: { [key: string]: string };
  }>({
    1: {
      weapon: "",
      armor: "",
      artefact: "",
    },
    2: {
      artefact: "",
    },
  });

  const handleSelectItem = (amount: number) => (type: string, id: string) => {
    if (amount === 1) {
      setSelectedItem((state) => ({
        ...state,
        1: {
          weapon: "",
          armor: "",
          artefact: "",
        },
      }));
    }
    if (selectedItem[amount][type] === id)
      return setSelectedItem((state) => ({
        ...state,
        [amount]: {
          ...state[amount],
          [type]: "",
        },
      }));

    setSelectedItem((state) => ({
      ...state,
      [amount]: {
        ...state[amount],
        [type]: id,
      },
    }));
  };

  const handleCraft = async () => {
    const itemId =
      selectedItem[1].weapon ||
      selectedItem[1].armor ||
      selectedItem[1].artefact;

    await toast.promise(
      () => mixWithMintSprig(itemId, selectedItem[2].artefact),
      {
        pending: "Craft is pending",
        success: "Craft resolved 👌",
        error: "Craft rejected 🤯",
      }
    );

    const data = await getItemStats(itemId);

    setCraftedItem({
      id: itemId,
      type: itemType[data[0]],
      selector: data[1],
      bonus: data[2],
      zodiac: data[3],
      requiredWins: data[4],
      vampiric: data[5],
      isStaked: false,
    });
    await getItems();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "28px",
      }}
    >
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "20px 0px",
          background: "rgba(217, 217, 217, 0.05)",
          width: "30%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Slot 1
        </Typography>
        <CustomChooseItem
          label={["Weapon", "Armor", "Artefact"]}
          handleSelectItem={handleSelectItem(1)}
          tabsContent={[
            {
              type: "weapon",
              items: items
                .filter((i) => i.type === "Weapon")
                .filter((i) => !i.isStaked),
              selected: selectedItem[1].weapon,
            },
            {
              type: "armor",
              items: items
                .filter((i) => i.type === "Armor")
                .filter((i) => !i.isStaked),
              selected: selectedItem[1].armor,
            },
            {
              type: "artefact",
              items: items
                .filter((i) => i.type === "Artefact")
                .filter((i) => i.selector !== "100")
                .filter((i) => !i.isStaked),
              selected: selectedItem[1].artefact,
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "20px 0px",
          background: "rgba(217, 217, 217, 0.05)",
          width: "30%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Slot 2
        </Typography>
        <CustomChooseItem
          label={["Mint sprig"]}
          handleSelectItem={handleSelectItem(2)}
          tabsContent={[
            {
              type: "artefact",
              items: items
                .filter((i) => i.type === "Artefact")
                .filter((i) => i.selector === "100")
                .filter((i) => !i.isStaked),
              selected: selectedItem[2].artefact,
            },
          ]}
        />
      </Box>
      <ResultCraft
        result={getResult(selectedItem)}
        disabled={
          (selectedItem[1].weapon ||
            selectedItem[1].armor ||
            selectedItem[1].artefact) &&
          selectedItem[2].artefact
            ? false
            : true
        }
        onClick={handleCraft}
      />
    </Box>
  );
};
