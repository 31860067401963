/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Button, TextField, Typography } from "@mui/material";
import { ChangeEvent } from "react";

type MarketplaceCardPropsCard = {
  title: string;
  img: string;
  value: string;
  btnTitle: string;
  placeholder: string;
  descriptions: string;
  imgSize?: { width: number; height: number };
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClick: () => void;
};

export const MarketplaceCard: React.FC<MarketplaceCardPropsCard> = ({
  title,
  img,
  value,
  btnTitle,
  placeholder,
  descriptions,
  imgSize = { width: 147, height: 98 },
  onChange,
  onClick,
}) => {
  return (
    <Box sx={{ width: "276px" }}>
      <Typography
        sx={{ fontSize: "30px", textAlign: "center", marginBottom: "20px" }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: "276px",
          border: "1px solid #FFFFFF",
          padding: "60px 24px 36px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
          src={img}
          alt="image"
          style={{
            width: `${imgSize.width}px`,
            height: `${imgSize.height}px`,
            objectFit: "cover",
          }}
        />
        <TextField
          id="filled-search"
          label={placeholder}
          variant="filled"
          sx={{ color: "white", width: "100%", marginTop: "16px" }}
          value={value}
          onChange={onChange}
        />
        <Button
          variant="contained"
          sx={{
            width: "100%",
            marginTop: "42px",
            fontSize: "12px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
            color: "rgba(0, 0, 0, 0.87)",
          }}
          onClick={onClick}
          disabled={!value || +value > 10}
        >
          {btnTitle}
        </Button>
      </Box>
      <Typography sx={{fontSize: '12px', textAlign: 'justify', marginTop: '45px'}}>{descriptions}</Typography>
    </Box>
  );
};
