import { Box, Typography } from '@mui/material';
import RoosterImg from '../../assets/img/rooster.png';
import moment from 'moment';
import { ChickenType } from '../../hooks';

type RoosterCardPropsType = {
  rooster: ChickenType;
  type?: 'rooster' | 'arena';
};

export const RoosterCard: React.FC<RoosterCardPropsType> = ({
  rooster,
  type = 'rooster',
}) => {
  if (!rooster) return <div>error: </div>;

  return (
    <Box sx={{ display: 'flex' }}>
      <img
        src={RoosterImg}
        alt="rooster"
        style={{
          width: '50%',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          borderLeft: '1px solid #333333',
          paddingLeft: '16px',
          textAlign: 'left',
        }}
      >
        {type === 'rooster' && (
          <Typography
            sx={{ paddingBottom: '15px', borderBottom: '1px solid white' }}
          >
            Wins: {rooster.wins}
          </Typography>
        )}
        <Typography sx={{ fontSize: type === 'rooster' ? '16px' : '12px' }}>
          Attack: {rooster.attack}
        </Typography>
        <Typography sx={{ fontSize: type === 'rooster' ? '16px' : '12px' }}>
          Defense: {rooster.defence}
        </Typography>
        <Typography sx={{ fontSize: type === 'rooster' ? '16px' : '12px' }}>
          Health: {rooster.health}
        </Typography>
        <Typography sx={{ fontSize: type === 'rooster' ? '16px' : '12px' }}>
          Speed: {rooster.speed}
        </Typography>
        <Typography sx={{ fontSize: type === 'rooster' ? '16px' : '12px' }}>
          Luck: {rooster.luck}
        </Typography>{' '}
        <Typography
          sx={{
            paddingBottom: type === 'rooster' ? '15px' : '',
            fontSize: type === 'rooster' ? '16px' : '12px',
          }}
        >
          Zodiac: {moment(Number(rooster.birthTimestamp) * 1000).day()}
        </Typography>
        {type === 'rooster' && (
          <Typography sx={{ borderTop: '1px solid white' }}>
            Birthday:{' '}
            {moment(Number(rooster.birthTimestamp) * 1000).format(
              'DD.MM.yyyy (ddd)'
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
