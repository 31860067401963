import { Box, Button, Typography } from "@mui/material";

type ResultCraftPropsType = {
  disabled: boolean;
  result: string;
  onClick: () => void;
};

export const ResultCraft: React.FC<ResultCraftPropsType> = ({
  result,
  disabled,
  onClick,
}) => {
  return (
    <Box
      sx={{
        width: "30%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Button
        variant="contained"
        color="success"
        sx={{
          height: "84px",
          fontSize: "14px",
          width: "100%",
        }}
        disabled={disabled}
        onClick={onClick}
      >
        craft
      </Button>
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "12px 38px 32px",
          background: "rgba(217, 217, 217, 0.05)",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "36px",
            lineHeight: "150%",
            textAlign: "center",
            marginBottom: "24px",
          }}
        >
          RESULT
        </Typography>
        <Box
          sx={{
            width: "100%",
            minHeight: "200px",
            border: "1px dashed rgb(204, 204, 204)",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "48px",
          }}
        >
          {result}
        </Box>
      </Box>
    </Box>
  );
};
