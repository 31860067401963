export type ItemType = "Weapon" | "Armor" | "Artefact" | "Female" | "Zombie";

export const getItemValue = (type?: ItemType, selector?: number) => {
  if (!type || !selector)
    return {
      name: "",
      bonus: "",
    };
  if (type === "Weapon") {
    if (selector > 0 && selector <= 20)
      return { name: "Dildo", bonus: "Attack + <value>" };
    else if (selector > 20 && selector <= 38)
      return {
        name: "Boxing Gloves",
        bonus: "Attack + <value>",
      };
    else if (selector > 38 && selector <= 54)
      return {
        name: "Cock-fu karate",
        bonus: "Attack + <value>",
      };
    else if (selector > 54 && selector <= 67)
      return {
        name: "Razor claws",
        bonus: "Attack + <value>",
      };
    else if (selector > 67 && selector <= 77)
      return { name: "Sword", bonus: "Attack + <value>" };
    else if (selector > 77 && selector <= 85)
      return {
        name: "Pistol guns",
        bonus: "Attack + <value>",
      };
    else if (selector > 85 && selector <= 91)
      return {
        name: "Machine Gun",
        bonus: "Attack + <value>",
      };
    else if (selector > 91 && selector <= 96)
      return {
        name: "Flame Thrower",
        bonus: "Attack + <value>",
      };
    else if (selector > 96 && selector <= 100)
      return { name: "Bazooka", bonus: "Attack + <value>" };
    ////Weapon end////////////////
  } else if (type === "Armor") {
    if (selector > 0 && selector <= 20)
      return { name: "Condom", bonus: "Defense + <value>" };
    else if (selector > 20 && selector <= 38)
      return {
        name: "Egg Shell Shield",
        bonus: "Defense + <value>",
      };
    else if (selector > 38 && selector <= 54)
      return {
        name: "Medieval Shield",
        bonus: "Defense + <value>",
      };
    else if (selector > 54 && selector <= 67)
      return {
        name: "Riot Shield",
        bonus: "Defense + <value>",
      };
    else if (selector > 67 && selector <= 77)
      return {
        name: "Flak Jacket",
        bonus: "Defense + <value>",
      };
    else if (selector > 77 && selector <= 85)
      return {
        name: "Kevlar vest",
        bonus: "Defense + <value>",
      };
    else if (selector > 85 && selector <= 91)
      return {
        name: "Plate mail armor",
        bonus: "Defense + <value>",
      };
    else if (selector > 91 && selector <= 96)
      return {
        name: "Mithril suit of armor",
        bonus: "Defense + <value>",
      };
    else if (selector > 96 && selector <= 100)
      return {
        name: "Dragon scales",
        bonus: "Defense + <value>",
      };
    ////Armor end////////////////
  } else if (type === "Artefact") {
    if (selector > 0 && selector <= 4)
      return {
        name: "Ankh of Ressurection",
        bonus: "One time use to not die in battle",
      };
    else if (selector > 4 && selector <= 11)
      return {
        name: "Warlock Ward",
        bonus: "Decrease spells efficiency -<value>%",
      };
    else if (selector > 11 && selector <= 18)
      return {
        name: "Go-Go Gandalf",
        bonus: "Increase spells efficiency +<value>%",
      };
    else if (selector > 18 && selector <= 27)
      return {
        name: "4 leaf clover",
        bonus: "Luck +<value>%",
      };
    else if (selector > 27 && selector <= 32)
      return { name: "Rabbit foot", bonus: "Luck +<value>%" };
    else if (selector > 32 && selector <= 34)
      return {
        name: "Glimmering horseshoe",
        bonus: "Luck +<value>%",
      };
    else if (selector > 34 && selector <= 43)
      return {
        name: "Evil eye / molochio",
        bonus: "Enemy Luck <value>%",
      };
    else if (selector > 43 && selector <= 48)
      return {
        name: "Voodoo doll",
        bonus: "Enemy Luck <value>%",
      };
    else if (selector > 48 && selector <= 50)
      return { name: "Horcrux", bonus: "Enemy Luck <value>%" };
    else if (selector > 50 && selector <= 59)
      return {
        name: "Peacock feathers",
        bonus: "HP +<value>%",
      };
    else if (selector > 59 && selector <= 64)
      return { name: "Tiger blood", bonus: "HP +<value>%" };
    else if (selector > 64 && selector <= 66)
      return { name: "Cock diesel", bonus: "HP +<value>%" };
    else if (selector === 67)
      return { name: "Jugger-cock", bonus: "HP +<value>%" };
    else if (selector > 67 && selector <= 76)
      return {
        name: "Caffeine",
        bonus: "Own speed +<value>%",
      };
    else if (selector > 76 && selector <= 81)
      return { name: "Aderral", bonus: "Own speed +<value>%" };
    else if (selector > 81 && selector <= 83)
      return { name: "Cocaine", bonus: "Own speed +<value>%" };
    else if (selector > 83 && selector <= 92)
      return {
        name: "Hashish Breath",
        bonus: "Enemy speed -<value>%",
      };
    else if (selector > 92 && selector <= 97)
      return {
        name: "Opium Aura",
        bonus: "Enemy speed -<value>%",
      };
    else if (selector > 97 && selector <= 99)
      return {
        name: "Heroin Stare",
        bonus: "Enemy speed -<value>%",
      };
    else if (selector === 100)
      return { name: "Mint Sprig", bonus: "For better craft" };

    /////end Artefact//////
  } else if (type === "Female") {
    if (selector > 0 && selector <= 40)
      return {
        name: "GMO breast juice",
        bonus: "Egg quality +<value>",
      };
    else if (selector > 40 && selector <= 70)
      return {
        name: "Free range feed",
        bonus: "Egg quality +<value>",
      };
    else if (selector > 70 && selector <= 90)
      return {
        name: "Ankh of fertility",
        bonus: "Egg quality +<value>",
      };
    else if (selector > 90 && selector <= 100)
      return {
        name: "Ovulator 9000",
        bonus: "Egg quality +<value>",
      };
    /////end Female//////
  }
};
