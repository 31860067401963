import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { ItemType } from "../helpers";
import { useContract } from "./useContract";

export type Item = {
  id: string;
  type: ItemType;
  selector: string;
  bonus: string;
  zodiac: string;
  requiredWins: string;
  vampiric: string;
  isStaked: boolean;
};

export const itemType = {
  0: "Weapon",
  1: "Armor",
  2: "Artefact",
  3: "Female",
  4: "Zombie",
};

export const useItems = () => {
  const { account } = useMetaMask();
  const { itemsContract } = useContract();

  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const getItems = async () => {
    setLoading(true);
    try {
      const items = await itemsContract.methods
        .getUserNftsWithParams(account)
        .call();

      const objArray = items.map((i) => {
        return {
          id: i[0],
          type: itemType[i[1]],
          selector: i[2],
          bonus: i[3],
          zodiac: i[4],
          requiredWins: i[5],
          vampiric: i[6],
          isStaked: Boolean(Number(i[7])),
        };
      });

      setItems(objArray);
    } catch (error) {
      console.log("getUserNftsWithParams: ", error);
    }
    setLoading(false);
  };

  const getItemStats = async (itemId: string) => {
    try {
      const res = await itemsContract.methods.getStats(+itemId).call();

      return res;
    } catch (error) {
      console.log("getItemStats error:", error);
    }
  };

  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const burnItems = async (ids: string[]) => {
    try {
      const res = await itemsContract.methods
        .burnMany(ids)
        .send({ from: account });
      return res;
    } catch (error) {
      console.log("burnItems: ", error);
    }
  };

  const buyMintSprigs = async (quantity: string) => {
    await itemsContract.methods.buyMintSprigs(quantity).send({
      from: account,
      gas: 2000000,
    });
  };

   const buyAnkh = async (quantity: string) => {
     await itemsContract.methods.buyAnkhs(quantity).send({
       from: account,
       gas: 2000000,
     });
   };

  return {
    items,
    isLoading,
    getItemStats,
    burnItems,
    setItems,
    getItems,
    buyMintSprigs,
    buyAnkh,
  };
};
