import Web3 from "web3/dist/web3.min.js";
import { BATTLE_ABI, BATTLE_ADDRESS } from "../contracts/battle";
import { CHICKEN_ABI, CHICKEN_ADDRESS } from "../contracts/chicken";
import { CONTRACT_ABI, CONTRACT_ADDRESS } from "../contracts/config";
import { CRAFT_ABI, CRAFT_ADDRESS } from "../contracts/craft";
import { EGGS_ADDRESS, EGGS_ABI } from "../contracts/eggs";
import { ITEMS_ABI, ITEMS_ADDRESS } from "../contracts/items";
import { SPELLS_ABI, SPELLS_ADDRESS } from "../contracts/spells";
import { VAULT_ABI, VAULT_ADDRESS } from "../contracts/vault";
import { connectContract } from "../helpers";

export const useContract = () => {
  const web3 = new Web3(window.ethereum);

  const contract = connectContract(web3, CONTRACT_ABI, CONTRACT_ADDRESS);
  const vault = connectContract(web3, VAULT_ABI, VAULT_ADDRESS);
  const eggsContract = connectContract(web3, EGGS_ABI, EGGS_ADDRESS);
  const chickensContract = connectContract(web3, CHICKEN_ABI, CHICKEN_ADDRESS);
  const itemsContract = connectContract(web3, ITEMS_ABI, ITEMS_ADDRESS);
  const spellsContract = connectContract(web3, SPELLS_ABI, SPELLS_ADDRESS);
  const battleContract = connectContract(web3, BATTLE_ABI, BATTLE_ADDRESS);
  const craftContract = connectContract(web3, CRAFT_ABI, CRAFT_ADDRESS);

  return {
    web3,
    contract,
    vault,
    eggsContract,
    chickensContract,
    itemsContract,
    spellsContract,
    battleContract,
    craftContract,
  };
};
