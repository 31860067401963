import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ResultCraft } from "../../components";
import { useCraft, useItems, itemType } from "../../hooks";
import { CraftPropsType } from "../../pages";
import { CustomChooseItem } from "../CustomChooseItem";

export const ItemsCraft: React.FC<CraftPropsType> = ({ setCraftedItem }) => {
  const { items, getItemStats, getItems } = useItems();
  const { mixWeaponOrArmor } = useCraft();
  const [selectedItem, setSelectedItem] = useState<{
    [key: number]: { [key: string]: string };
  }>({
    1: {
      weapon: "",
      armor: "",
    },
    2: {
      weapon: "",
      armor: "",
    },
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    setSelectedItem({
      1: {
        weapon: "",
        armor: "",
      },
      2: {
        weapon: "",
        armor: "",
      },
    });
  }, [selectedTab]);

  const handleSelectItem = (amount: number) => (type: string, id: string) => {
    if (selectedItem[amount][type] === id)
      return setSelectedItem((state) => ({
        ...state,
        [amount]: {
          ...state[amount],
          [type]: "",
        },
      }));
    setSelectedItem((state) => ({
      ...state,
      [amount]: {
        ...state[amount],
        [type]: id,
      },
    }));
  };

  const handleCraft = async () => {
    const func =
      selectedItem[1].weapon && selectedItem[2].weapon
        ? mixWeaponOrArmor(selectedItem[1].weapon, selectedItem[2].weapon)
        : mixWeaponOrArmor(selectedItem[1].armor, selectedItem[2].armor);
    await toast.promise(() => func, {
      pending: "Craft is pending",
      success: "Craft resolved 👌",
      error: "Craft rejected 🤯",
    });

    const data = await getItemStats(
      selectedItem[1].weapon && selectedItem[2].weapon
        ? selectedItem[1].weapon
        : selectedItem[1].armor
    );

    setCraftedItem({
      id:
        selectedItem[1].weapon && selectedItem[2].weapon
          ? selectedItem[1].weapon
          : selectedItem[1].armor,
      type: itemType[data[0]],
      selector: data[1],
      bonus: data[2],
      zodiac: data[3],
      requiredWins: data[4],
      vampiric: data[5],
      isStaked: false,
    });
    await getItems();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "28px",
      }}
    >
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "20px 0px",
          background: "rgba(217, 217, 217, 0.05)",
          width: "30%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Slot 1
        </Typography>
        <CustomChooseItem
          label={["Weapon", "Armor"]}
          handleSelectItem={handleSelectItem(1)}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabsContent={[
            {
              type: "weapon",
              items: items
                .filter((i) => i.type === "Weapon")
                .filter((i) => !i.isStaked)
                .filter((i) => i.id !== selectedItem[2].weapon),
              selected: selectedItem[1].weapon,
            },
            {
              type: "armor",
              items: items
                .filter((i) => i.type === "Armor")
                .filter((i) => !i.isStaked)
                .filter((i) => i.id !== selectedItem[2].armor),
              selected: selectedItem[1].armor,
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "20px 0px",
          background: "rgba(217, 217, 217, 0.05)",
          width: "30%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Slot 2
        </Typography>
        <CustomChooseItem
          label={["Weapon", "Armor"]}
          handleSelectItem={handleSelectItem(2)}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabsContent={[
            {
              type: "weapon",
              items: items
                .filter((i) => i.type === "Weapon")
                .filter((i) => !i.isStaked)
                .filter((i) => i.id !== selectedItem[1].weapon),
              selected: selectedItem[2].weapon,
            },
            {
              type: "armor",
              items: items
                .filter((i) => i.type === "Armor")
                .filter((i) => !i.isStaked)
                .filter((i) => i.id !== selectedItem[1].armor),
              selected: selectedItem[2].armor,
            },
          ]}
        />
      </Box>
      <ResultCraft
        result={
          selectedItem[1].weapon && selectedItem[2].weapon
            ? "Weapon"
            : selectedItem[1].armor && selectedItem[2].armor
            ? "Armor"
            : "?"
        }
        disabled={
          (selectedItem[1].weapon && selectedItem[2].weapon) ||
          (selectedItem[1].armor && selectedItem[2].armor)
            ? false
            : true
        }
        onClick={handleCraft}
      />
    </Box>
  );
};
