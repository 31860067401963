import { useMetaMask } from "metamask-react";
import { useState } from "react";
import { useContract } from "./useContract";

export type Round = {
  round: number;
  hp_1: string;
  hp_2: string;
  spell_id_1: string;
  spell_id_2: string;
  crit_1: string;
  crit_2: string;
  card: string;
};

export type Rooster = {
  attack: string;
  defence: string;
  health: string;
  speed: string;
  luck: string;
  wins: string;
  birthTimestamp: string;
  id: string;
  isWinner?: boolean;
};

export const useBattle = () => {
  const { account } = useMetaMask();
  const { battleContract } = useContract();

  const [rounds, setRounds] = useState<Round[]>([
    // {
    //   card: "113",
    //   crit_1: "100",
    //   crit_2: "100",
    //   hp_1: "54",
    //   hp_2: "52",
    //   round: 1,
    //   spell_id_1: "0",
    //   spell_id_2: "0",
    // },
    // {
    //   card: "5",
    //   crit_1: "100",
    //   crit_2: "100",
    //   hp_1: "37",
    //   hp_2: "34",
    //   round: 2,
    //   spell_id_1: "0",
    //   spell_id_2: "0",
    // },
    // {
    //   card: "10",
    //   crit_1: "100",
    //   crit_2: "100",
    //   hp_1: "22",
    //   hp_2: "22",
    //   round: 3,
    //   spell_id_1: "0",
    //   spell_id_2: "0",
    // },
    // {
    //   card: "9",
    //   crit_1: "100",
    //   crit_2: "100",
    //   hp_1: "6",
    //   hp_2: "8",
    //   round: 4,
    //   spell_id_1: "0",
    //   spell_id_2: "0",
    // },
  ]);
  const [ownRooster, setOwnRooster] = useState<null | Rooster>(null)
  //   {
  //   attack: "26",
  //   birthTimestamp: "1658244259",
  //   defence: "32",
  //   health: "27",
  //   id: "7",
  //   isWinner: false,
  //   luck: "24",
  //   speed: "20",
  //   wins: "0",
  // });
  const [opponentRooster, setOpponentRooster] = useState<null | Rooster>(null)
  //   {
  //   attack: "25",
  //   birthTimestamp: "1658249671",
  //   defence: "31",
  //   health: "26",
  //   id: "55",
  //   isWinner: true,
  //   luck: "24",
  //   speed: "20",
  //   wins: "0",
  // });
  const [usedSpells, setUsedSpells] = useState<string[]>([]);

  const fightNow = async (
    roosterId: string,
    itemIds: string[],
    spellIds: string[],
    commandString: string = "AAAAAA"
  ) => {
    const res = await battleContract.methods
      .fightNow(roosterId, itemIds, spellIds, commandString)
      .send({
        from: account,
      });

    const ownRooster =
      res.events.FightEventRoosters.returnValues.Rooster1Equipped;
    setOwnRooster({
      attack: ownRooster[0],
      defence: ownRooster[1],
      health: ownRooster[2],
      speed: ownRooster[3],
      luck: ownRooster[4],
      wins: ownRooster[5],
      birthTimestamp: ownRooster[6],
      id: res.events.FightEventRoosters.returnValues.rooster1Id,
      isWinner:
        res.events.FightEventRoosters.returnValues.rooster1Id ===
        res.events.FightEvent.returnValues.winnerRoosterId,
    });

    const opponentRooster =
      res.events.FightEventRoosters.returnValues.Rooster2Equipped;
    setOpponentRooster({
      attack: opponentRooster[0],
      defence: opponentRooster[1],
      health: opponentRooster[2],
      speed: opponentRooster[3],
      luck: opponentRooster[4],
      wins: opponentRooster[5],
      birthTimestamp: opponentRooster[6],
      id: res.events.FightEventRoosters.returnValues.rooster2Id,
      isWinner:
        res.events.FightEventRoosters.returnValues.rooster2Id ===
        res.events.FightEvent.returnValues.winnerRoosterId,
    });

    const array = res.events.FightEvent.returnValues.fightStory.map(
      (i, index) => {
        return {
          round: index + 1,
          hp_1: i[0],
          hp_2: i[1],
          spell_id_1: i[2],
          spell_id_2: i[3],
          crit_1: i[4],
          crit_2: i[5],
          card: i[6],
        };
      }
    );
    const spell_ids_1 = array.map((i) => i.spell_id_1).filter((i) => i !== "0");
    const spell_ids_2 = array.map((i) => i.spell_id_2).filter((i) => i !== "0");

    //@ts-ignore
    const uniqueIds = [...new Set([...spell_ids_1, ...spell_ids_2])];
    setUsedSpells(uniqueIds);

    setRounds(array);
  };
  return { usedSpells, ownRooster, opponentRooster, rounds, fightNow };
};
