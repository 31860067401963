import { useMetaMask } from 'metamask-react';
import { toast } from 'react-toastify';
import { useContract } from './useContract';

export const useCraft = () => {
  const { craftContract } = useContract();
  const { account } = useMetaMask();

  const mixWeaponOrArmor = async (mainItemId: string, burnItemId: string) => {
    await craftContract.methods.mixWeaponOrArmor(mainItemId, burnItemId).send({
      from: account,
      gas: 200000,
    });
  };

  const mixSacrificeChicken = async (
    mainItemId: string,
    burnChickenId: string
  ) => {
    await craftContract.methods
      .mixSacrificeChicken(mainItemId, burnChickenId)
      .send({
        from: account,
        gas: 500000,
      });
  };

  const mixDoubleSacrifice = async (chickenId1: string, chickenId2: string) => {
    const newItemId = await craftContract.methods
      .mixDoubleSacrifice(chickenId1, chickenId2)
      .call({
        from: account,
        gas: 500000,
      });

    await toast.promise(
      () =>
        craftContract.methods.mixDoubleSacrifice(chickenId1, chickenId2).send({
          from: account,
          gas: 500000,
        }),
      {
        pending: 'Craft is pending',
        success: 'Craft resolved 👌',
        error: 'Craft rejected 🤯',
      }
    );

    return newItemId;
  };

  const mixWithMintSprig = async (itemId: string, sprigId: string) => {
    await craftContract.methods.mixWithMintSprig(itemId, sprigId).send({
      from: account,
    });
  };

  return {
    mixWeaponOrArmor,
    mixSacrificeChicken,
    mixDoubleSacrifice,
    mixWithMintSprig,
  };
};
