import DashboardIcon from "@mui/icons-material/Dashboard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import WarningIcon from "@mui/icons-material/Warning";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { NavLink, useLocation } from "react-router-dom";
import s from "./SideBar.module.css";

const links = [
  {
    title: "Dashboard",
    href: "/",
    icon: <DashboardIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Deposit",
    href: "/deposit",
    icon: <ArrowForwardIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Marketplace",
    href: "/marketplace",
    icon: <ShoppingCartIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Roosters",
    href: "/roosters",
    icon: <GroupIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Hens",
    href: "/hens",
    icon: <AccountCircleIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Eggs",
    href: "/eggs",
    icon: <PanoramaFishEyeIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Items",
    href: "/items",
    icon: <ContentCutIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Spells",
    href: "/spells",
    icon: <StarHalfIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Hatching",
    href: "/hatching",
    icon: <QueryBuilderIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Breeding",
    href: "/breeding",
    icon: <FavoriteIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Battle arena",
    href: "/arena",
    icon: <WarningIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
  {
    title: "Witchcraft doctor",
    href: "/doctor",
    icon: <RemoveRedEyeIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />,
  },
];

export const SideBar = () => {
  const { pathname } = useLocation();

  return (
    <div className={s.wrapper}>
      <div>
        <ul className={s.links}>
          {links.map((i) => (
            <NavLink
              key={i.href}
              to={i.href}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div
                className={
                  pathname !== i.href ? s.linkWrapper : s.activeWrapper
                }
              >
                {i.icon}
                <p className={s.linkTitle}>{i.title}</p>
              </div>
            </NavLink>
          ))}
        </ul>
      </div>
      <button className={s.button}>
        <LogoutIcon style={{ color: "rgba(255, 255, 255, 0.56" }} />
        <p className={s.buttonTitle}>Log Out</p>
      </button>
    </div>
  );
};
