import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { StackedCarousel } from 'react-stacked-carousel';
import 'react-stacked-carousel/dist/index.css';
import { IconButton } from '@mui/material';
import { ReactNode } from 'react';

type CarouselTypeProps = {
  children: ReactNode;
  disabled: boolean;
};

export const Carousel: React.FC<CarouselTypeProps> = ({
  children,
  disabled,
}) => {
  return (
    <div className="carousel_container">
      <div>
        <StackedCarousel
          autoRotate={false}
          containerClassName="container"
          cardClassName="card"
          leftButton={
            <IconButton
              sx={{
                color: 'rgba(255, 255, 255, 0.54)',
                fontSize: '13px',
              }}
              aria-label="add to shopping cart"
              disabled={disabled}
            >
              <ChevronLeftIcon />
            </IconButton>
          }
          rightButton={
            <IconButton
              sx={{
                color: 'rgba(255, 255, 255, 0.54)',
                fontSize: '13px',
              }}
              aria-label="add to shopping cart"
              disabled={disabled}
            >
              <ChevronRightIcon />
            </IconButton>
          }
        >
          {children}
        </StackedCarousel>
      </div>
    </div>
  );
};
