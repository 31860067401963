import { Box, Typography } from "@mui/material";
import { getItemValue } from "../../helpers";
import { Item } from "../../hooks";
import WeaponImg from "../../assets/img/bazooka.png";
import ArmorImg from "../../assets/img/vest.png";
import ArtefactImg from "../../assets/img/clover.png";
import { SelectedItem } from "../ChosseItem";

type ArenaSelectedItemsPropsType = {
  items: Item[];
  selectedItem: SelectedItem;
};

const imgObj = {
  Weapon: WeaponImg,
  Armor: ArmorImg,
  Artefact: ArtefactImg,
};

export const ArenaSelectedItems: React.FC<ArenaSelectedItemsPropsType> = ({
  items,
  selectedItem,
}) => {
  return (
    <>
      {["Weapon", "Armor", "Artefact"].map((i) => {
        const item = items.find(
          (item) => item.id === selectedItem[i.toLowerCase()]
        );
        const data = getItemValue(item?.type, Number(item?.selector));

        return (
          <Box
            key={i}
            sx={{
              width: "31%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              
            }}
          >
            <Typography>{i}</Typography>
            {item ? (
              <Box
                sx={{
                  width: "100%",
                  height: "131px",
                  border: "1px solid #CCCCCC",
                  borderRadius: "15px",
                  marginTop: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  
                }}
              >
                <img
                  src={imgObj[i]}
                  alt="item"
                  style={{
                    width: "45%",
                    objectFit: "cover",
                  }}
                />
                <Typography
                  sx={{
                    borderTop: "1px solid #CCCCCC",
                    width: "100%",
                    height: "100%",
                    fontSize: "15px",
                    fontWeight: "500",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {data?.bonus.replace("<value>", item.bonus)}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "131px",
                  border: "1px dashed rgb(204, 204, 204)",
                  borderRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                  marginTop: "5px",
                }}
              ></Box>
            )}
          </Box>
        );
      })}
    </>
  );
};
