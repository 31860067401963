import { Box, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Spells } from "../../hooks";
import { ReviewCardCenterChild, SpellCard } from "../../components";
import { Dispatch, SetStateAction, useState } from "react";

type MultiSelectedSpellPropsType = {
  spells: Spells[];
  selectedSpells: string[];
  setSelectedSpells: Dispatch<SetStateAction<string[]>>;
};

export const MultiSelectedSpell: React.FC<MultiSelectedSpellPropsType> = ({
  spells,
  selectedSpells,
  setSelectedSpells,
}) => {
  const [showedSpells, setShowedSpells] = useState<number[]>([0, 1, 2]);

  const decrement = () =>
    setShowedSpells((state) => [state[0] - 1, state[1] - 1, state[2] - 1]);
  const increment = () =>
    setShowedSpells((state) => [state[0] + 1, state[1] + 1, state[2] + 1]);

  const selectHandler = (id: string) => () => {
    if (selectedSpells.find((i) => i === id)) {
      return setSelectedSpells((state) => state.filter((i) => i !== id));
    }
    if (selectedSpells.length === 3) return;
    setSelectedSpells((state) => [...state, id]);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={{ color: "rgba(255, 255, 255, 0.54)", fontSize: "13px" }}
          aria-label="add to shopping cart"
          onClick={decrement}
          disabled={showedSpells[0] === 0}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: "100%", display: "flex" }}>
        {spells[showedSpells[0]] ? (
          <ReviewCardCenterChild
            title={`Spell ID #${spells[showedSpells[0]].id}`}
            secondBtnTitle="Select"
            secondButtonHandler={selectHandler(spells[showedSpells[0]].id)}
            isSelected={Boolean(
              selectedSpells.find((i) => i === spells[showedSpells[0]].id)
            )}
          >
            <SpellCard spell={spells[showedSpells[0]]} type="battle" />
          </ReviewCardCenterChild>
        ) : (
          <Box
            sx={{
              width: "33%",
              border: "1px dashed rgb(204, 204, 204)",

              borderRadius: "15px",
              minHeight: "200px",
            }}
          ></Box>
        )}
        {spells[showedSpells[1]] ? (
          <Box sx={{ margin: "0px 10px" }}>
            <ReviewCardCenterChild
              title={`Spell ID #${spells[showedSpells[1]].id}`}
              secondBtnTitle="Select"
              secondButtonHandler={selectHandler(spells[showedSpells[1]].id)}
              isSelected={Boolean(
                selectedSpells.find((i) => i === spells[showedSpells[1]].id)
              )}
            >
              <SpellCard spell={spells[showedSpells[1]]} type="battle" />
            </ReviewCardCenterChild>
          </Box>
        ) : (
          <Box
            sx={{
              width: "33%",
              border: "1px dashed rgb(204, 204, 204)",
              margin: "0px 10px",
              borderRadius: "15px",
              minHeight: "200px",
            }}
          ></Box>
        )}
        {spells[showedSpells[2]] ? (
          <ReviewCardCenterChild
            title={`Spell ID #${spells[showedSpells[2]].id}`}
            secondBtnTitle="Select"
            secondButtonHandler={selectHandler(spells[showedSpells[2]].id)}
            isSelected={Boolean(
              selectedSpells.find((i) => i === spells[showedSpells[2]].id)
            )}
          >
            <SpellCard spell={spells[showedSpells[2]]} type="battle" />
          </ReviewCardCenterChild>
        ) : (
          <Box
            sx={{
              width: "33%",
              border: "1px dashed rgb(204, 204, 204)",

              borderRadius: "15px",
              minHeight: "200px",
            }}
          ></Box>
        )}
      </Box>
      <Box
        sx={{
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={{ color: "rgba(255, 255, 255, 0.54)", fontSize: "13px" }}
          aria-label="add to shopping cart"
          onClick={increment}
          disabled={showedSpells[2] + 1 >= spells.length}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
