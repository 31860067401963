import { Box, Button, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Carousel,
  ReviewCardCenterChild,
  RoosterCard,
  RoosterCharacteristics,
} from '../../components';
import {
  ArenaSelectedItems,
  BattleResult,
  ChosseItem,
  ActionsBattle,
  MultiSelectedSpell,
  SelectedItem,
} from '../../containers';
import { useBalanceContext } from '../../context';
import {
  ChickenType,
  useBattle,
  useChickens,
  useItems,
  useSpells,
} from '../../hooks';

export const BattleArena = () => {
  const { roosters: allRoosters } = useChickens();
  const { items } = useItems();
  const { spells, getSpellStats } = useSpells();
  const { ownRooster, opponentRooster, rounds, usedSpells, fightNow } =
    useBattle();

  const { getDeposit, getWalletBalance } = useBalanceContext();

  //filter not staked rooster
  const roosters = useMemo(() => {
    return allRoosters.filter((i) => !i.isStaked);
  }, [allRoosters]);

  const [selectedRooster, setSelectedRooster] = useState<ChickenType[]>([]);
  const [selectedItem, setSelectedItem] = useState<SelectedItem>({
    weapon: '0',
    armor: '0',
    artefact: '0',
  } as SelectedItem);
  const [selectedSpells, setSelectedSpells] = useState<string[]>([]);
  const [actions, setActions] = useState<{ [key: string]: 'A' | 'S' }>({
    1: 'A',
    2: 'A',
    3: 'A',
    4: 'A',
    5: 'A',
    6: 'A',
  });

  const selectHandler = (rooster: ChickenType) => () =>
    selectedRooster.filter((i) => i.id === rooster.id).length
      ? setSelectedRooster([])
      : setSelectedRooster([rooster]);

  const fightHandler = async () => {
    const spellsIds = !selectedSpells.length
      ? ['0', '0', '0']
      : selectedSpells.length === 1
      ? [selectedSpells[0], '0', '0']
      : selectedSpells.length === 2
      ? [selectedSpells[0], selectedSpells[1], '0']
      : selectedSpells;

    const itemIds: string[] = Object.values(selectedItem);
    const actionsString = Object.values(actions).join('');
    await toast.promise(
      () => fightNow(selectedRooster[0].id, itemIds, spellsIds, actionsString),
      {
        pending: 'Fight is pending',
        success: 'Fight resolved 👌',
        error: 'Fight rejected 🤯',
      }
    );
    setTimeout(() => {
      getDeposit();
      getWalletBalance();
    }, 15000);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '20px',
          marginLeft: '20px',
          marginTop: '32px',
          marginBottom: '20px',
        }}
      >
        Battle arena
      </Typography>
      {!rounds.length || !ownRooster || !opponentRooster ? (
        <Box
          sx={{ marginLeft: '10px', display: 'flex', alignItems: 'flex-start' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              width: '334px',
              minHeight: '346px'
            }}
          >
            <Box
              sx={{
                width: "100%",
                 minHeight: '346px',
                border: '1px solid #CCCCCC',
                padding: '20px 25px',
                marginBottom: '10px',
                background: 'rgba(217, 217, 217, 0.05)',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Choose your fighter
              </Typography>
              {roosters.length  ? (
                <Carousel disabled={roosters.length <= 1}>
                  {roosters.map((i) => {
                    return (
                      <Fragment key={i.id}>
                        <ReviewCardCenterChild
                          title={`Rooster ID #${i.id}`}
                          secondBtnTitle={'Select'}
                          secondButtonHandler={selectHandler(i)}
                          isSelected={Boolean(
                            selectedRooster.filter((j) => j.id === i.id).length
                          )}
                          isStaked={i.isStaked}
                        >
                          <RoosterCard rooster={i} type="arena" />
                        </ReviewCardCenterChild>
                      </Fragment>
                    );
                  })}
                </Carousel>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    minHeight: '200px',
                    border: '1px dashed rgb(204, 204, 204)',
                    borderRadius: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '20px',
                  }}
                >
                  Empty
                </Box>
              )}
            </Box>
            <Box
              sx={{
                border: '1px solid #CCCCCC',
                padding: '20px 0px',
                background: 'rgba(217, 217, 217, 0.05)',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Choose items
              </Typography>
              <ChosseItem
                items={
                  !selectedRooster[0]
                    ? []
                    : items.filter(
                        (i) =>
                          Number(i.requiredWins) <=
                          Number(selectedRooster[0].wins)
                      )
                }
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </Box>
            <Button
              variant="contained"
              color="success"
              sx={{
                height: '84px',
                fontSize: '14px',
                width: '100%',
                margin: '30px 0px',
              }}
              onClick={fightHandler}
            >
              Go fight!
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              margin: '0px 20px 30px',
            }}
          >
            <Box
              sx={{
                border: '1px solid #CCCCCC',
                padding: '20px 20px 40px',
                background: 'rgba(217, 217, 217, 0.05)',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  textAlign: 'center',
                  marginBottom: '30px',
                }}
              >
                {selectedRooster[0]
                  ? `Rooster ID #${selectedRooster[0].id} | Wins: ${selectedRooster[0].wins}`
                  : 'Choose rooster'}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '20px',
                  }}
                >
                  {selectedRooster[0] ? (
                    <RoosterCharacteristics
                      selectedRooster={{
                        attack: String(
                          items.find((item) => item.id === selectedItem.weapon)
                            ? Number(
                                items.find(
                                  (item) => item.id === selectedItem.weapon
                                )?.bonus
                              ) + Number(selectedRooster[0].attack)
                            : selectedRooster[0].attack || '0'
                        ),
                        defence: String(
                          items.find((item) => item.id === selectedItem.armor)
                            ? Number(
                                items.find(
                                  (item) => item.id === selectedItem.armor
                                )?.bonus
                              ) + Number(selectedRooster[0].defence)
                            : selectedRooster[0].defence || '0'
                        ),
                        speed: selectedRooster[0].speed,
                        luck: selectedRooster[0].luck,
                        health: selectedRooster[0].health,
                        birthTimestamp: selectedRooster[0].birthTimestamp,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '396px',
                        border: '1px dashed rgb(204, 204, 204)',
                        background: 'rgba(217, 217, 217, 0.05)',
                      }}
                    ></Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <ArenaSelectedItems
                      items={items}
                      selectedItem={selectedItem}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: '50%' }}>
                  <ActionsBattle actions={actions} setActions={setActions} />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                border: '1px solid #CCCCCC',
                padding: '20px 20px 40px',
                background: 'rgba(217, 217, 217, 0.05)',
                marginTop: '10px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Choose spells
              </Typography>
              <MultiSelectedSpell
                spells={
                  !selectedRooster[0]
                    ? []
                    : spells
                        .filter(
                          (i) =>
                            Number(i.requiredWins) <=
                            Number(selectedRooster[0].wins)
                        )
                        .filter((i) => !i.isStaked)
                }
                selectedSpells={selectedSpells}
                setSelectedSpells={setSelectedSpells}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <BattleResult
          rounds={rounds}
          ownRooster={ownRooster}
          opponentRooster={opponentRooster}
          getSpellStats={getSpellStats}
          usedSpells={usedSpells}
        />
      )}
    </Box>
  );
};
