import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useLinkClickHandler } from "react-router-dom";

type ReviewCardPropsType = {
  title: string;
  centerContent: string | number;
  titleFirstBtn: string;
  titleSecondBtn?: string;
  isLoading?: boolean;
  firstLinkTo?: string;
  seconsdLinkTo?: string;
};

export const ReviewCard: React.FC<ReviewCardPropsType> = ({
  title,
  firstLinkTo,
  seconsdLinkTo,
  centerContent,
  titleFirstBtn,
  titleSecondBtn,
  isLoading,
}) => {
  const hatchHandlerFirstButton = useLinkClickHandler(
    firstLinkTo ? firstLinkTo : "/"
  ) as () => void;

  const hatchHandlerSecondButton = useLinkClickHandler(
    seconsdLinkTo ? seconsdLinkTo : "/"
  ) as () => void;
  return (
    <Card
      sx={{
        minWidth: "33%",
        bgcolor: "transparent",
        border: "1px solid #FFFFFF",
        color: "#FFFFFF",
        fontSize: "16px",
      }}
    >
      <CardHeader
        sx={{ padding: "16px" }}
        avatar={
          <Avatar
            sx={{
              bgcolor: "#757575",
              width: "40px",
              height: "40px",
            }}
            aria-label="recipe"
          >
            {title[0]}
          </Avatar>
        }
        title={title}
      />
      <CardContent sx={{ bgcolor: "#262626", textAlign: "center" }}>
        {isLoading ? <CircularProgress size={15}/> : centerContent}
      </CardContent>
      <CardContent sx={{ textAlign: "left", padding: "0px" }}>
        <IconButton
          sx={{ color: "#90CAF9", fontSize: "13px" }}
          aria-label="add to shopping cart"
          onClick={hatchHandlerFirstButton}
        >
          <RemoveRedEyeIcon />{" "}
          <Typography
            sx={{
              marginLeft: "6px",
              fontSize: "13px",
              textTransform: "uppercase",
            }}
          >
            {titleFirstBtn}
          </Typography>
        </IconButton>
        {titleSecondBtn && (
          <IconButton
            sx={{ color: "#90CAF9", fontSize: "13px" }}
            aria-label="add to shopping cart"
            onClick={hatchHandlerSecondButton}
          >
            <RemoveRedEyeIcon />{" "}
            <Typography
              sx={{
                marginLeft: "6px",
                fontSize: "13px",
                textTransform: "uppercase",
              }}
            >
              {titleSecondBtn}
            </Typography>
          </IconButton>
        )}
      </CardContent>
    </Card>
  );
};
