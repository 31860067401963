import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { SelectItem } from "../SelectItem";
import { ChickenType, Item } from "../../hooks";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

type CustomChooseItemPropsType = {
  label: string[];
  tabsContent: {
    type: string;
    items: Item[] | ChickenType[];
    selected: string;
  }[];
  selectedTab?: number;
  setSelectedTab?: Dispatch<SetStateAction<number>>;
  handleSelectItem: (type: string, id: string) => void;
};

export const CustomChooseItem: React.FC<CustomChooseItemPropsType> = ({
  label,
  tabsContent,
  selectedTab,
  setSelectedTab,
  handleSelectItem,
}) => {
  const theme = useTheme();
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    if (!setSelectedTab) return;
    setSelectedTab(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (selectedTab || selectedTab === 0) {
      setSelected(selectedTab);
    }
  }, [selectedTab]);

  const handleChange = (_: SyntheticEvent, newValue: number) =>
    setSelected(newValue);

  const handleChangeIndex = (index: number) => setSelected(index);

  const handleSelect = (type: string, id: string) => () =>
    handleSelectItem(type, id);
  return (
    <Box sx={{ bgcolor: "background.paper", minWidth:200 }}>
      
      <AppBar position="static">
        <Tabs
          value={selected}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {label.map((i, index) => (
            <Tab
              label={i}
              {...a11yProps(index)}
              sx={{ fontSize: "12px" }}
              key={index}
            />
          ))}
        </Tabs>
      </AppBar>
      <Box sx={{ paddingTop: "10px", background: "rgba(217, 217, 217, 0.05)" }}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={selected}
          onChangeIndex={handleChangeIndex}
        >
          {tabsContent.map((i, index) => (
            <TabPanel
              value={selected}
              index={index}
              dir={theme.direction}
              key={index}
            >
              <SelectItem
                items={i.items}
                selectedItemId={i.selected}
                handleSelect={handleSelect}
                type={i.type}
              />
            </TabPanel>
          ))}
        </SwipeableViews>
      </Box>
    </Box>
  );
};
