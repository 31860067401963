import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import EggsImg from "../../assets/img/eggs.png";
import { Egg } from "../../hooks";

type MultipleSelectedEggsPropsType = {
  selectedEggs: Egg[];
  hatchHandler: () => void;
};

export const MultipleSelectedEggs: React.FC<MultipleSelectedEggsPropsType> = ({
  selectedEggs,
  hatchHandler,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={EggsImg}
          alt="eggs"
          style={{ height: "200px", marginLeft: "100px" }}
        />
        <Button
          variant="contained"
          sx={{ padding: "6px 50px", marginTop: "10px", marginLeft: "100px" }}
          onClick={hatchHandler}
        >
          Hatch all selected
        </Button>
      </Box>
      <TableContainer sx={{ maxWidth: 600, marginRight: "30px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: "1px solid #575757" }} align="left">
                №
              </TableCell>
              <TableCell sx={{ border: "1px solid #575757" }} align="left">
                ID
              </TableCell>
              <TableCell sx={{ border: "1px solid #575757" }} align="left">
                Quality
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedEggs.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell sx={{ border: "1px solid #575757" }} align="left">
                  {index + 1}
                </TableCell>
                <TableCell sx={{ border: "1px solid #575757" }} align="left">
                  {row.id}
                </TableCell>
                <TableCell sx={{ border: "1px solid #575757" }} align="left">
                  {row.quality}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
