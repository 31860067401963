import { Box, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import EggsImg from "../../assets/img/eggs.png";
import MintImg from "../../assets/img/mint.png";
import AnkhImg from "../../assets/img/ankh.png";
import { MarketplaceCard } from "../../components";
import { useBalanceContext } from "../../context";
import { setNumberInputValue } from "../../helpers";
import { useEggs, useItems } from "../../hooks";

export const Marketplace = () => {
  const { buyEgg } = useEggs();
  const { buyMintSprigs, buyAnkh } = useItems();
  const { getDeposit, getWalletBalance } = useBalanceContext();

  const [value, setValue] = useState<{
    eggValue: string;
    mintValue: string;
    ankhValue: string;
  }>({
    eggValue: "",
    mintValue: "",
    ankhValue: "",
  });

  const handleChangeValue =
    (type: "eggValue" | "mintValue" | "ankhValue") =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, notShow, valueFloat } = setNumberInputValue(
        e.target.value
      );
      if (isNaN(valueFloat) || notShow) return;
      setValue((state) => ({
        ...state,
        [type]: value,
      }));
    };

  const hadleBuyEggs = async () => {
    await toast.promise(() => buyEgg(+value.eggValue), {
      pending: "Buying eggs is pending",
      success: "Buying eggs resolved 👌",
      error: "Buying eggs rejected 🤯",
    });
    setValue((state) => ({
      ...state,
      eggValue: "",
    }));
    await getDeposit();
    await getWalletBalance();
  };

  const hadleBuyMints = async () => {
    await toast.promise(() => buyMintSprigs(value.mintValue), {
      pending: "Buying mints is pending",
      success: "Buying mints resolved 👌",
      error: "Buying mints rejected 🤯",
    });
    setValue((state) => ({
      ...state,
      mintValue: "",
    }));
    await getDeposit();
    await getWalletBalance();
  };

  const hadleBuyAnkh = async () => {
    await toast.promise(() => buyAnkh(value.ankhValue), {
      pending: "Buying ankh is pending",
      success: "Buying ankh resolved 👌",
      error: "Buying ankh rejected 🤯",
    });
    setValue((state) => ({
      ...state,
      ankhValue: "",
    }));
    await getDeposit();
    await getWalletBalance();
  };

  return (
    <Box sx={{ margin: "0px 24px" }}>
      <Typography
        sx={{
          fontSize: "20px",
          lineHeight: "160%",
          letterSpacing: "0.15px",
          margin: "32px 0px",
        }}
      >
        Marketplace
      </Typography>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <MarketplaceCard
          title="Eggs"
          img={EggsImg}
          value={value.eggValue}
          placeholder="Enter the egg amount"
          btnTitle={
            !value.eggValue
              ? "buy _ egg and pay _ rhc"
              : `buy ${value.eggValue} egg and pay ${value.eggValue} rhc`
          }
          onChange={handleChangeValue("eggValue")}
          onClick={hadleBuyEggs}
          descriptions="Type amount of eggs you want to buy and press the button"
        />
        <Box sx={{ marginLeft: "24px" }}>
          <MarketplaceCard
            title="Mint sprig"
            img={MintImg}
            value={value.mintValue}
            placeholder="Enter mint sprig amount"
            btnTitle={
              !value.mintValue
                ? "buy _ mint and pay _ rhc"
                : `buy ${value.mintValue} mint and pay ${
                    +value.mintValue * 10
                  } rhc`
            }
            imgSize={{ width: 98, height: 98 }}
            onChange={handleChangeValue("mintValue")}
            onClick={hadleBuyMints}
            descriptions="Mint sprig helps you to craft better items. Type amount of items you want to buy and press the button"
          />
        </Box>
        <Box sx={{ marginLeft: "24px" }}>
          <MarketplaceCard
            title="Ankh of resurrection"
            img={AnkhImg}
            value={value.ankhValue}
            placeholder="Enter ankh amount you want to buy"
            btnTitle={
              !value.ankhValue
                ? "buy _ ankh and pay _ rhc"
                : `buy ${value.ankhValue} ankh and pay ${
                    +value.ankhValue * 50
                  } rhc`
            }
            imgSize={{ width: 90, height: 100 }}
            onChange={handleChangeValue("ankhValue")}
            onClick={hadleBuyAnkh}
            descriptions="Ankh of resurrection can save the life of your rooster if he loses the fight. Type amount of items you want to buy and press the button"
          />
        </Box>
      </Box>
    </Box>
  );
};
