import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { ReactNode } from "react";
import { Button } from "@mui/material";
import { useLinkClickHandler } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import StakedIcon from "../../assets/img/stackedLabel.png";

type ReviewCardCenterChildPropsType = {
  id?: string;
  title: string;
  titleBtn?: string;
  link?: string;
  secondBtnTitle?: string;
  thirdBtnTitle?: string | ReactNode;
  children: ReactNode;
  disabledButton?: boolean;
  isSelected?: boolean;
  isStaked?: boolean;
  buttonHandler?: () => void;
  secondButtonHandler?: () => void;
  thirdButtonHandler?: () => void;
};

export const ReviewCardCenterChild: React.FC<
  ReviewCardCenterChildPropsType
> = ({
  id,
  link,
  title,
  titleBtn,
  children,
  isSelected,
  isStaked,
  disabledButton,
  secondBtnTitle,
  thirdBtnTitle,
  buttonHandler,
  secondButtonHandler,
  thirdButtonHandler,
}) => {
  const hatchHandler = useLinkClickHandler(link ? link : "/", {
    state: id,
  }) as () => void;
  return (
    <Card
      sx={{
        minWidth: "33%",
        background: "#121212",
        border: "1px solid #333333",
        color: "#FFFFFF",
        fontSize: "16px",
        height: "100%",
      }}
    >
      <CardHeader
        sx={{ padding: "16px", zIndex: "0" }}
        avatar={
          <Avatar
            sx={{
              bgcolor: "#757575",
              width: "40px",
              height: "40px",
              zIndex: "0",
            }}
            aria-label="recipe"
          >
            {title[0] || ""}
          </Avatar>
        }
        title={title}
        action={
          isStaked ? (
            <img
              src={StakedIcon}
              alt="rooster"
              style={{
                width: "40%",
                objectFit: "cover",
                position: "relative",
                left: "40px",
              }}
            />
          ) : (
            ""
          )
        }
      />

      <CardContent
        sx={{
          bgcolor: "#262626",
          textAlign: "center",
        }}
      >
        {children}
      </CardContent>
      <CardContent
        sx={{
          width: "100%",
          marginBottom: "15px",
          display: "flex",
          alignItems: "center",
          flexDirection: thirdBtnTitle ? "column" : "row",
          justifyContent:
            secondBtnTitle && titleBtn ? "space-between" : "center",
        }}
      >
        {thirdBtnTitle && (
          <Button
            variant="contained"
            sx={{
              padding: "0px 5px",
              marginBottom: "20px",
              width: thirdBtnTitle && "70%",
            }}
            onClick={thirdButtonHandler}
          >
            {thirdBtnTitle as string}
          </Button>
        )}
        {titleBtn && (
          <Button
            variant="contained"
            sx={{
              padding: "0px 5px",
              width: Boolean(thirdBtnTitle) ? "70%" : "auto",
            }}
            onClick={!link ? buttonHandler : hatchHandler}
            disabled={disabledButton}
          >
            {titleBtn}
          </Button>
        )}
        {secondBtnTitle &&
          (!isSelected ? (
            <Button
              variant="contained"
              endIcon={<CheckBoxOutlineBlankIcon />}
              sx={{ padding: "0px 5px", fontSize: "14px" }}
              onClick={secondButtonHandler}
            >
              {secondBtnTitle}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              endIcon={<CheckBoxIcon />}
              sx={{ padding: "0px 5px", fontSize: "14px" }}
              onClick={secondButtonHandler}
            >
              {secondBtnTitle}
            </Button>
          ))}
      </CardContent>
    </Card>
  );
};
