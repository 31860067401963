import { createContext, useContext } from "react";

type BalanceContextType = {
  matic: number;
  token: number;
  deposit: number;
  isLoadingDeposit: boolean;
  getDeposit: () => void;
  getWalletBalance: () => void;
};

const balanceContextDefault: BalanceContextType = {
  matic: 0,
  token: 0,
  deposit: 0,
  isLoadingDeposit: true,
  getDeposit: () => {},
  getWalletBalance: () => {},
};

export const BalanceContext = createContext(balanceContextDefault);

export const useBalanceContext = () => useContext(BalanceContext);
