import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  DepositForm,
  MetamaskSvg,
  ReviewCardCenterChild,
} from "../../components";
import { useBalanceContext } from "../../context";
import { VAULT_ADDRESS } from "../../contracts/vault";
import { sendMatic, sendToken } from "../../helpers";
import { useContract } from "../../hooks";

const rpcUrls =
  "https://polygon-mumbai.g.alchemy.com/v2/uto78miEz7j_tDPuMpoR-NZnd19NIPNy";

const gridStyles = {
  paddingBottom: 2,
  paddingRight: 2,
  marginTop: 2,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "repeat(3, 1fr)",
};

const depositCard = [
  {
    type: "amount",
    title: "Balances",
    titleBtn: "import tokens",
    descriptions: `1.Add Mumbai network to your Metamask, press the button.\n2.In case if you already have added Mumbai network in metamask,than please change New RPC URL in Settings > Network > Polygon Testnet. Paste this url: ${rpcUrls}.\n3.Import tokens to Metamask. Press the button.`,
  },
  {
    type: "claim",
    title: "Claim",
    titleBtn: "Claim",
    descriptions:
      "For the demo you can calm tokens to play without limits. In case if you have less than 1000 RHC or 0,1 MATIC, you can calm tokens/ Just press the button.",
  },
  {
    type: "deposit",
    title: "Approve and deposit",
    titleBtn: "Approve and depoosit",
    descriptions:
      "So now you have tokens on your wallet. Make a deposit to the game ballance. Type amount of RHC tokens you want to deposit to the game balance apd press the button. After this step you will be able play the game without limits",
  },
];

const networks = {
  polygon: {
    chainId: `0x13881`,
    chainName: "Polygon Testnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [rpcUrls],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
};

const changeNetwork = async () => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...networks["polygon"],
        },
      ],
    } as { method: string });
  } catch (error) {
    console.log("changeNetwork:", error);
  }
};

export const Deposit = () => {
  const { account } = useMetaMask();

  const { web3, contract, vault } = useContract();
  const { matic, token, deposit, getDeposit, getWalletBalance } =
    useBalanceContext();

  const [depositAmount, setDepositAmount] = useState<string>("");
  const [claimDisabled, setClaimDisabled] = useState<boolean>(true);
  const [depositDisabled, setDepositDisabled] = useState<{
    disabled: boolean;
    msg: string;
  }>({ disabled: true, msg: "" });

  //check is claim disabled
  useEffect(() => {
    if (token >= 1000 && matic >= 0.1) setClaimDisabled(true);
  }, [matic, token]);

  //check user wallet token amount and input value
  useEffect(() => {
    if (!depositAmount) {
      setDepositDisabled((state) => ({
        ...state,
        disabled: true,
        msg: "",
      }));
      return;
    }
    setDepositDisabled((state) => ({
      ...state,
      disabled: token < +depositAmount,
      msg: token < +depositAmount ? "You need more token!!!!!" : "",
    }));
  }, [token, depositAmount]);

  const handleClaim = async () => {
    if (!account) return;
    setClaimDisabled(true);

    const promise = async () => {
      if (+matic < 0.1) await sendMatic(account, web3.utils.toWei("0.1"));

      if (+token < 1000) await sendToken(account, web3.utils.toWei("1000"));

      setTimeout(() => {
        getDeposit();
        getWalletBalance();
      }, 2000);
    };

    toast.promise(promise, {
      pending: "Deposit is pending",
      success: "Deposit resolved 👌",
      error: "Deposit rejected 🤯",
    });
  };

  const handleImportToken = async () => {
    const address = "0x374D22d3f2e5838010F19D0D21260d29faaE8539";
    const symbol = "RHC";
    const decimals = 18;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address,
            symbol,
            decimals,
          },
        },
      } as { method: string });
    } catch (error) {}
  };

  const handleApproveDeposit = async () => {
    const approveDeposit = async () => {
      try {
        await contract.methods
          .approve(VAULT_ADDRESS, web3.utils.toWei(depositAmount))
          .send({
            from: account,
          });
      } catch (e) {
        console.log("approve: ", e);
      }

      try {
        await vault.methods.deposit(web3.utils.toWei(depositAmount)).send({
          from: account,
          gas: 100000,
        });
      } catch (e) {
        console.log("deposit: ", e);
      }

      await getDeposit();
      await getWalletBalance();

      setDepositAmount("");
    };

    toast.promise(approveDeposit, {
      pending: "Deposit is pending",
      success: "Deposit resolved 👌",
      error: "Deposit rejected 🤯",
    });
  };

  const handleNetworkSwitch = async () => await changeNetwork();

  const children = {
    amount: (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "200px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            width: "50%",
            fontSize: "24px",
          }}
        >
          <Typography sx={{ marginBottom: "40px", fontSize: "24px" }}>
            Game balance:
          </Typography>
          <Typography sx={{ fontSize: "24px" }}>{deposit} RHC</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            width: "50%",
          }}
        >
          <Typography sx={{ marginBottom: "40px", fontSize: "24px" }}>
            Wallet balance:
          </Typography>
          <Typography sx={{ fontSize: "24px" }}>{token} RHC</Typography>
          <Typography sx={{ fontSize: "24px" }}>
            {matic.toFixed(2)} MATIC
          </Typography>
        </Box>
      </Box>
    ),
    claim: (
      <Typography
        sx={{
          fontSize: "24px",
          minHeight: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        1000 RHC | 0.1 MATIC
      </Typography>
    ),
    deposit: (
      <Box sx={{ minHeight: "200px", width: "100%" }}>
        <DepositForm
          value={depositAmount}
          setValue={setDepositAmount}
          errorMsg={depositDisabled.msg}
        />
      </Box>
    ),
  };

  const buttonsHandlerObj = {
    amount: handleImportToken,
    claim: handleClaim,
    deposit: handleApproveDeposit,
  };
  return (
    <Box>
      <Typography
        sx={{ fontSize: "20px", marginLeft: "20px", marginTop: "32px" }}
      >
        Deposit
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        columns={16}
        sx={gridStyles}
      >
        {depositCard.map((i, index) => (
          <Grid
            item
            xs={5}
            key={index}
            // sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box sx={{ height: "405px" }}>
              <ReviewCardCenterChild
                title={i.title}
                titleBtn={i.titleBtn}
                buttonHandler={buttonsHandlerObj[i.type]}
                disabledButton={
                  i.type === "claim"
                    ? claimDisabled
                    : i.type === "deposit"
                    ? depositDisabled.disabled
                    : undefined
                }
                thirdBtnTitle={
                  i.type === "amount" ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Add mumbai network
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "5px",
                        }}
                      >
                        <MetamaskSvg />
                      </Box>
                    </Box>
                  ) : undefined
                }
                thirdButtonHandler={
                  i.type === "amount" ? handleNetworkSwitch : undefined
                }
              >
                {children[i.type]}
              </ReviewCardCenterChild>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "14px",
                }}
              >
                Step {index + 1}
              </Typography>
              <Typography sx={{ fontSize: "12px", whiteSpace: "pre-line" }}>
                {i.descriptions}
              </Typography>
            </Box>
            {/* </Box> */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
