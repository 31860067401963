import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { SelectItem } from "../SelectItem";
import { Item } from "../../hooks";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export type SelectedItem = {
  weapon: string;
  armor: string;
  artefact: string;
};

type ChosseItemPropsType = {
  items: Item[];
  selectedItem: SelectedItem;
  setSelectedItem: Dispatch<SetStateAction<SelectedItem>>;
};

export const ChosseItem: React.FC<ChosseItemPropsType> = ({
  items,
  selectedItem,
  setSelectedItem,
}) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleChange = (_: SyntheticEvent, newValue: number) =>
    setSelectedTab(newValue);

  const handleChangeIndex = (index: number) => setSelectedTab(index);

  const handleSelect =
    (type: string, id: string) => () => {
      if (selectedItem[type] === id)
        return setSelectedItem((state) => ({
          ...state,
          [type]: "0",
        }));
      setSelectedItem((state) => ({
        ...state,
        [type]: id,
      }));
    };

  return (
    <div>
      <Box sx={{ bgcolor: "background.paper", width: 330 }}>
        <AppBar position="static">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Weapon" {...a11yProps(0)} sx={{ fontSize: "12px" }} />
            <Tab label="Armor" {...a11yProps(1)} sx={{ fontSize: "12px" }} />
            <Tab label="Artefact" {...a11yProps(2)} sx={{ fontSize: "12px" }} />
          </Tabs>
        </AppBar>
        <Box
          sx={{ paddingTop: "10px", background: "rgba(217, 217, 217, 0.05)" }}
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={selectedTab}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={selectedTab} index={0} dir={theme.direction}>
              <SelectItem
                items={items.filter((i) => i.type === "Weapon")}
                selectedItemId={selectedItem.weapon}
                handleSelect={handleSelect}
                type="weapon"
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1} dir={theme.direction}>
              <SelectItem
                items={items.filter((i) => i.type === "Armor")}
                selectedItemId={selectedItem.armor}
                handleSelect={handleSelect}
                type="armor"
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={2} dir={theme.direction}>
              <SelectItem
                items={items.filter((i) => i.type === "Artefact")}
                selectedItemId={selectedItem.artefact}
                handleSelect={handleSelect}
                type="artefact"
              />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>
    </div>
  );
};
