import { Button } from "@mui/material";
import { useMetaMask } from "metamask-react";
import CockImg from "../../assets/img/cock.png";
import s from "./NotAuthContainers.module.css";

export const NotAuthContainers = () => {
const { connect} = useMetaMask();

const handleConnect = () => connect()

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <p className={s.header}>Connect wallet to enter the game</p>
        <img src={CockImg} alt="cock" className={s.img} />
        <Button
          onClick={handleConnect}
          variant="contained"
          style={{
            background: "#90CAF9",
            width: "480px",
            height: "55px",
            color: "black",
            marginTop: "68px",
          }}
        >
          + Connect wallet
        </Button>
      </div>
    </div>
  );
};
