import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FavouriteSlider,
  MoonSvg,
  RoosterCharacteristics,
} from "../../components";
import { getSpellValue } from "../../helpers";
import { getCardValue } from "../../helpers/getCardValue";
import { Rooster, Round } from "../../hooks";

type BattleResultPropsType = {
  ownRooster: Rooster;
  opponentRooster: Rooster;
  rounds: Round[];
  usedSpells: string[];
  getSpellStats: (spellId: string) => Promise<string[]>;
};

export const BattleResult: React.FC<BattleResultPropsType> = ({
  ownRooster,
  opponentRooster,
  rounds,
  usedSpells,
  getSpellStats,
}) => {
  const [spells, setSpells] = useState<
    { id: string; selector: string; bonus: string; requiredWins: string }[]
  >([]);
  const [ownHp, setOwnHp] = useState<number>(Number(ownRooster.health) * 2);
  const [opponentHp, setOpponentHp] = useState<number>(
    Number(opponentRooster.health) * 2
  );
  const [intervalRounds, setIntervalRounds] = useState<Round[]>([]);

  useEffect(() => {
    let count = -1;
    const interval = setInterval(() => {
      if (count >= rounds.length - 1) return;
      count = count + 1;
      setIntervalRounds((state) => [...state, rounds[count]]);
    }, 4000);

    return () => clearInterval(interval);
  }, [rounds]);

  const getSpellsById = async (id: string) => {
    const d = await getSpellStats(id);

    setSpells((state) => [
      ...state,
      { id, selector: d[0], bonus: d[1], requiredWins: d[2] },
    ]);
  };

  useEffect(() => {
    if (!usedSpells.length) return;
    usedSpells.map((i) => getSpellsById(i));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedSpells]);

  useEffect(() => {
    if (!intervalRounds[intervalRounds.length - 1]) return;
    setOwnHp(
      (state) =>
        state -
        (rounds.length === intervalRounds.length && ownRooster.isWinner
          ? 0
          : Number(intervalRounds[intervalRounds.length - 1].hp_1) -
            Number(
              rounds[intervalRounds.length]
                ? rounds[intervalRounds.length].hp_1
                : 0
            ))
    );
  }, [intervalRounds, ownRooster, rounds]);

  useEffect(() => {
    if (!intervalRounds[intervalRounds.length - 1]) return;
    setOpponentHp(
      (state) =>
        state -
        (rounds.length === intervalRounds.length && opponentRooster.isWinner
          ? 0
          : Number(intervalRounds[intervalRounds.length - 1].hp_2) -
            Number(
              rounds[intervalRounds.length]
                ? rounds[intervalRounds.length].hp_2
                : 0
            ))
    );
  }, [intervalRounds, opponentRooster, rounds]);

  return (
    <Box
      sx={{
        marginLeft: "10px",
        marginRight: "10px",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "33%" }}>
        <Box
          sx={{
            border: "1px solid #CCCCCC",
            padding: "20px",
            background: "rgba(217, 217, 217, 0.05)",
            width: "100%",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", textAlign: "center", paddingBottom: "5px" }}
          >
            Rooster ID #{ownRooster.id} | Wins: {ownRooster.wins}
          </Typography>
          <RoosterCharacteristics selectedRooster={ownRooster} />
          <Box
            sx={{
              marginTop: "13px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "75%" }}>
              <FavouriteSlider
                maxValue={Number(ownRooster.health) * 2}
                value={ownHp}
              />
            </Box>
            <Typography sx={{ marginLeft: "10px" }}>{ownHp} HP</Typography>
          </Box>
        </Box>
        <Typography
          sx={{ fontSize: "96px", textAlign: "center", marginTop: "50px" }}
        >
          {intervalRounds.length === rounds.length
            ? ownRooster.isWinner
              ? "Winner"
              : "Looser"
            : ""}
        </Typography>
        {ownRooster.isWinner &&
          intervalRounds.length ===
            rounds.length && (
              <Box>
                <Typography sx={{ fontSize: "20px", textAlign: "center" }}>
                  Rewards:
                </Typography>
                <Typography sx={{ fontSize: "20px", textAlign: "center" }}>
                  +1 win
                </Typography>
                <Typography sx={{ fontSize: "20px", textAlign: "center" }}>
                  +10 egg quality
                </Typography>
                <Typography sx={{ fontSize: "20px", textAlign: "center" }}>
                  +{ownRooster.luck} RHC
                </Typography>
              </Box>
            )}
      </Box>
      <Box
        sx={{
          border: "1px solid #CCCCCC",
          padding: "20px 20px 10px",
          background: "rgba(217, 217, 217, 0.05)",
          marginLeft: "10px",
          marginRight: "10px",
          width: "33%",
        }}
      >
        {intervalRounds.map((i, index, array) => {
          const hp1 = rounds[index + 1]
            ? `${Number(rounds[index + 1].hp_1) - Number(i.hp_1)} HP`
            : opponentRooster.isWinner
            ? `-${i.hp_1} HP`
            : "";

          const hp2 = rounds[index + 1]
            ? `${Number(rounds[index + 1].hp_2) - Number(i.hp_2)} HP`
            : ownRooster.isWinner
            ? `-${i.hp_2} HP`
            : "";

          const spell1 = spells.find((spell) => spell.id === i.spell_id_1);
          const spell2 = spells.find((spell) => spell.id === i.spell_id_2);

          return (
            <Box
              key={i.round}
              sx={{
                border: "1px solid #CCCCCC",
                marginBottom: "10px",
                background: "rgba(217, 217, 217, 0.05)",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "24px",
                  borderBottom: "1px solid #CCCCCC",
                }}
              >
                Round {i.round}
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid #CCCCCC",
                  display: "flex",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid #CCCCCC",
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#262626",
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      padding: "5px 2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MoonSvg />
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      marginLeft: "5px",
                    }}
                  >
                    {getCardValue(i.card)?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                    {getCardValue(i.card)?.value}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    left: "40%",
                    right: "40%",
                    fontWeight: "500",
                    fontSize: "14px",
                    borderBottom: "1px solid #CCCCCC",
                    borderLeft: "1px solid #CCCCCC",
                    borderRight: "1px solid #CCCCCC",
                  }}
                >
                  Actions
                </Typography>
                <Box
                  sx={{
                    width: "50%",
                    borderRight: hp2 ? "1px solid #CCCCCC" : "",
                    height: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px 20px",
                    marginTop: "22px",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                    {hp2
                      ? Number(i.spell_id_1)
                        ? spell1
                          ? `Spell (${getSpellValue(
                              Number(spell1!.selector)
                            )?.battleBonus.replace("<value>", spell1!.bonus)})`
                          : ""
                        : "Attack"
                      : ""}
                  </Typography>

                  <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                    {hp2}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px 20px",
                    marginTop: "22px",
                    borderLeft: !hp2 ? "1px solid #CCCCCC" : "",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                    {hp1
                      ? Number(i.spell_id_2)
                        ? spell2
                          ? `Spell (${getSpellValue(
                              Number(spell2!.selector)
                            )?.battleBonus.replace("<value>", spell2!.bonus)})`
                          : ""
                        : "Attack"
                      : " "}
                  </Typography>

                  <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                    {hp1}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ width: "33%" }}>
        <Box
          sx={{
            border: "1px solid #CCCCCC",
            padding: "20px",
            background: "rgba(217, 217, 217, 0.05)",
            width: "100%",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", textAlign: "center", paddingBottom: "5px" }}
          >
            Rooster ID #{opponentRooster.id} | Wins: {opponentRooster.wins}
          </Typography>
          <RoosterCharacteristics selectedRooster={opponentRooster} />
          <Box
            sx={{
              marginTop: "13px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "75%" }}>
              <FavouriteSlider
                maxValue={Number(opponentRooster.health) * 2}
                value={opponentHp}
              />
            </Box>
            <Typography sx={{ marginLeft: "10px" }}>{opponentHp} HP</Typography>
          </Box>
        </Box>
        <Typography
          sx={{ fontSize: "96px", textAlign: "center", marginTop: "50px" }}
        >
          {intervalRounds.length === rounds.length
            ? opponentRooster.isWinner
              ? "Winner"
              : "Looser"
            : ""}
        </Typography>
      </Box>
    </Box>
  );
};
