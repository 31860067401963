import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  HatchingResult,
  MultiCarousel,
  MultipleSelectedEggs,
  SingleSelectedEgg,
} from "../../containers";
import { Egg, useEggs } from "../../hooks";

export const Hatching = () => {
  const { eggs, hatchEggs, hatchedEggs } = useEggs();

  const { state } = useLocation();
  const [selectedEggs, setSelectedEggs] = useState<Egg[]>([]);

  useEffect(() => {
    if (!state || !eggs.length) return;

    setSelectedEggs((s) => eggs.filter((i) => i.id === state));
  }, [state, eggs]);

  const hatchHandler = async () => {
    await toast.promise(() => hatchEggs(selectedEggs.map((i) => i.id)), {
      pending: "Hatching is pending",
      success: "Hatching resolved 👌",
      error: "Hatching rejected 🤯",
    });
    setSelectedEggs([]);
  };

  return (
    <>
      <Box sx={{ minHeight: "350px" }}>
        <Typography
          sx={{
            fontSize: "20px",
            marginLeft: "20px",
            marginTop: "32px",
            marginBottom: "32px",
          }}
        >
          Eggs | Total: {eggs.length}
        </Typography>

        <MultiCarousel
          eggs={eggs}
          setSelectedEggs={setSelectedEggs}
          selectedEggs={selectedEggs}
        />
      </Box>
      {hatchedEggs.length ? (
        <HatchingResult hatchedEggs={hatchedEggs} />
      ) : (
        <></>
      )}
      {selectedEggs.length === 1 && (
        <SingleSelectedEgg egg={selectedEggs[0]} hatchHandler={hatchHandler} />
      )}
      {selectedEggs.length > 1 && (
        <MultipleSelectedEggs
          selectedEggs={selectedEggs}
          hatchHandler={hatchHandler}
        />
      )}
    </>
  );
};
