import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { PairType } from "../../pages";

export const calculateTimeLeft = (date: number) => {
  let difference = +new Date(date) - +new Date();

  let timeLeft = {
    days: 0,
    hours: "00",
    minutes: "00",
    seconds: "00",
  };

  const stringHours =
    Math.floor((difference / (1000 * 60 * 60)) % 24) < 10
      ? `0${Math.floor((difference / (1000 * 60 * 60)) % 24)}`
      : `${Math.floor((difference / (1000 * 60 * 60)) % 24)}`;
  const stringMinutes =
    Math.floor((difference / 1000 / 60) % 60) < 10
      ? `0${Math.floor((difference / 1000 / 60) % 60)}`
      : `${Math.floor((difference / 1000 / 60) % 60)}`;
  const stringSeconds =
    Math.floor((difference / 1000) % 60) < 10
      ? `0${Math.floor((difference / 1000) % 60)}`
      : `${Math.floor((difference / 1000) % 60)}`;

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: stringHours,
      minutes: stringMinutes,
      seconds: stringSeconds,
    };
  }

  return timeLeft;
};

const getFirstEggStatus = (minutes: number) => {
  if (minutes >= 20) {
    return "First egg in progress...";
  } else if (minutes < 20) {
    return "First egg is ready!";
  }
};

const getSecondEggStatus = (minutes: number) => {
  if (minutes > 20) {
    return "";
  } else if (minutes < 20 && minutes >= 10) {
    return "Second egg in progress...";
  } else if (minutes < 10) {
    return "Second egg is ready!";
  }
};

const getThirdEggStatus = (minutes: number, seconds: number) => {
  if (!minutes && !seconds) {
    return "Third egg is ready!";
  }

  if (minutes < 10 && minutes >= 0) {
    return "Third egg in progress...";
  }
  return "";
};

type BreedingTimerPropType = {
  amount: number;
  selectedPairs: { [key: string]: PairType };
  handleClaim: () => Promise<void>;
  handleUnstake: () => Promise<void>;
};

export const BreedingTimer: React.FC<BreedingTimerPropType> = ({
  amount,
  selectedPairs,
  handleClaim,
  handleUnstake,
}) => {
  const [timeLeft, setTimeLeft] = useState<{
    hours: string;
    minutes: string;
    seconds: string;
    days: number;
  } | null>(null);

  useEffect(() => {
    if (!selectedPairs[amount].createTime) return;

    const timer = setTimeout(() => {
      setTimeLeft(
        calculateTimeLeft(
          Number(selectedPairs[amount].createTime) * 1000 + 0.5 * 60 * 60 * 1000
        )
      );
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          border: "1px solid #CCCCCC",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            height: "200px",
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRight: "1px solid #CCCCCC",
          }}
        >
          {timeLeft && `0${timeLeft?.minutes[1]}:${timeLeft?.seconds}`}
        </Box>
        <Box sx={{ width: "50%" }}>
          <Box
            sx={{
              height: "50px",
              borderBottom: "1px solid #CCCCCC",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {timeLeft ? getFirstEggStatus(Number(timeLeft.minutes)) : ""}
          </Box>
          <Box
            sx={{
              height: "50px",
              borderBottom: "1px solid #CCCCCC",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {timeLeft ? getSecondEggStatus(Number(timeLeft.minutes)) : ""}
          </Box>
          <Box
            sx={{
              height: "50px",
              borderBottom: "1px solid #CCCCCC",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {timeLeft
              ? getThirdEggStatus(
                  Number(timeLeft.minutes),
                  Number(timeLeft.seconds)
                )
              : ""}
          </Box>
          <Box
            sx={{
              height: "50px",
              padding: "0px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ width: "60%", fontSize: "12px" }}
              variant="contained"
              onClick={handleClaim}
              disabled={!(Number(timeLeft?.minutes) <= 19)}
            >
              Claim
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Button
          sx={{ width: "30%" }}
          variant="contained"
          onClick={handleUnstake}
        >
          unstake
        </Button>
      </Box>
    </Box>
  );
};
