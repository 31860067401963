import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { useContract } from "./useContract";

export const useBalance = () => {
  const { account } = useMetaMask();
  const { web3, contract, vault } = useContract();

  const [matic, setMatic] = useState<number>(0);
  const [token, setToken] = useState<number>(0);
  const [deposit, setDeposit] = useState<number>(0);
  const [isLoadingDeposit, setLoadingDeposit] = useState<boolean>(true);

  const getDeposit = async () => {
    setLoadingDeposit(true);
    try {
      const deposit = await vault.methods.getBalance(account).call();
      setDeposit(+web3.utils.fromWei(deposit));
    } catch (e) {
      console.log("getDeposit error:", e);
    }

    setLoadingDeposit(false);
  };

  const getWalletBalance = async () => {
    try {
      const maticBalance = await web3.eth.getBalance(account);
      const matic = +web3.utils.fromWei(maticBalance);
      setMatic(matic);
    } catch (e) {
      console.log("get matic error: ", e);
    }

    try {
      const wei = await contract.methods.balanceOf(account).call();
      const token = +web3.utils.fromWei(wei);
      setToken(token);
    } catch (e) {
      console.log("get token error: ", e);
    }
  };

  useEffect(() => {
    if (!account) return;
    getWalletBalance();
    getDeposit();
    // eslint-disable-next-line
  }, [account]);

  return {
    isLoadingDeposit,
    matic,
    token,
    deposit,
    getDeposit,
    getWalletBalance,
  };
};
