export const getSpellValue = (selector: number) => {
  if (selector > 0 && selector <= 5)
    return {
      name: "Sleep",
      bonus: "Enemy skips next 2 rounds. Chance to work <value>%",
      battleBonus: "Skips next 2 rounds",
    };
  else if (selector > 5 && selector <= 20)
    return {
      name: "Heal",
      bonus: "+<value>% from total amount of cocks HP",
      battleBonus: "+<value>% HP",
    };
  else if (selector > 20 && selector <= 30)
    return {
      name: "Buff",
      bonus: "+<value>% from total amount of cocks Attack",
      battleBonus: "+<value>% Attack",
    };
  else if (selector > 30 && selector <= 40)
    return {
      name: "Turtle",
      bonus: "+<value>% from total amount of cocks Deffence",
      battleBonus: "+<value>% Deffence",
    };
  else if (selector > 40 && selector <= 50)
    return {
      name: "Shine",
      bonus: "+<value>% from total amount of cocks Luck",
      battleBonus: "+<value>% Luck",
    };
  else if (selector > 50 && selector <= 60)
    return {
      name: "Nitro",
      bonus: "+<value>% from total amount of cocks Speed",
      battleBonus: "+<value>% Speed",
    };
  else if (selector > 60 && selector <= 70)
    return {
      name: "Curse",
      bonus: "-<value>% from total amount of cocks Luck",
      battleBonus: "-<value>% Luck",
    };
  else if (selector > 70 && selector <= 80)
    return {
      name: "Weaken",
      bonus: "-<value>% from total amount of cocks Attack",
      battleBonus: "-<value>% Attack",
    };
  else if (selector > 80 && selector <= 90)
    return {
      name: "Fear",
      bonus: "-<value>% from total amount of cocks Deffence",
      battleBonus: "-<value>% Deffence",
    };
  else if (selector > 90 && selector <= 100)
    return {
      name: "Slow",
      bonus: "-<value>% from total amount of cocks Speed",
      battleBonus: "-<value>% Speed",
    };
};
