import { Box, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ReactNode } from 'react';

type SingleSelectCardPropsType = {
  decrement?: () => void;
  increment?: () => void;
  showedItem: number;
  itemsLength: number;
  children: ReactNode;
  childrenWidth: number;
  isDisabledBtn?: boolean;
};

export const SingleSelectCard: React.FC<SingleSelectCardPropsType> = ({
  decrement,
  increment,
  showedItem,
  itemsLength,
  children,
  childrenWidth,
  isDisabledBtn,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {decrement && (
        <Box
          sx={{
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <IconButton
            sx={{ color: 'rgba(255, 255, 255, 0.54)', fontSize: '13px' }}
            aria-label="add to shopping cart"
            onClick={decrement}
            disabled={showedItem === 0 || !itemsLength || isDisabledBtn}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      )}
      <Box sx={{ width: `${childrenWidth}px` }}>{children}</Box>
      {increment && (
        <Box
          sx={{
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <IconButton
            sx={{ color: 'rgba(255, 255, 255, 0.54)', fontSize: '13px' }}
            aria-label="add to shopping cart"
            onClick={increment}
            disabled={
              isDisabledBtn
                ? isDisabledBtn
                : showedItem === itemsLength - 1 || !itemsLength
            }
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
