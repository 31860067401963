/* eslint-disable array-callback-return */
import { Box, Button, Modal, Typography } from '@mui/material';
import {
  BreedingTimer,
  calculateTimeLeft,
  Carousel,
  HenCard,
  ItemCard,
  ReviewCardCenterChild,
  RoosterCard,
  SingleSelectCard,
} from '../../components';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChickenType, Egg, Item } from '../../hooks';
import { PairType } from '../../pages';
import { ClaimEggsModal, modalStyle } from '../ClaimEggsModal';
import { toast } from 'react-toastify';

const EmptyList: React.FC<{ style: { [key: string]: string } }> = ({
  style,
}) => {
  return (
    <Box
      sx={{
        border: '1px dashed rgb(204, 204, 204)',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        ...style,
      }}
    >
      Empty
    </Box>
  );
};

type SelectBreedingPairPropsType = {
  amount: number;
  items: Item[];
  roosters: ChickenType[];
  hens: ChickenType[];
  selectedPairs: { [key: string]: PairType };
  stakeHenHouse: (
    roosterId: string,
    henId: string,
    itemId?: string
  ) => Promise<void>;
  setSelectedPairs: Dispatch<SetStateAction<{ [key: string]: PairType }>>;
  unstakeHenHouse: (id: string) => Promise<void>;
  claimEggs: (id: string) => Promise<any>;
  getUserHenHouses: () => Promise<string[][]>;
  getEggStats: (id: string) => Promise<string[]>;
};

export const SelectBreedingPair: React.FC<SelectBreedingPairPropsType> = ({
  hens,
  items,
  amount,
  roosters,
  selectedPairs,
  claimEggs,
  getEggStats,
  stakeHenHouse,
  setSelectedPairs,
  unstakeHenHouse,
  getUserHenHouses,
}) => {
  const [showedRooster, setShowedRooster] = useState<number>(0);
  const [showedHen, setShowedHen] = useState<number>(0);
  const [showedItem, setShowedItem] = useState<number>(0);
  const [claimEggsArray, setClaimEggsArray] = useState<Egg[]>([]);
  const [isOpenApprovedClaim, setIsOpenApprovedClaim] =
    useState<boolean>(false);

  const femaleItem = useMemo(() => {
    return items.filter((i) => i.type === 'Female');
  }, [items]);

  const notStackedRoosters = useMemo(() => {
    return roosters.filter((i) => !i.isStaked);
  }, [roosters]);

  const notStackedHens = useMemo(() => {
    return hens.filter((i) => !i.isStaked);
  }, [hens]);

  const notStackedItems = useMemo(() => {
    return femaleItem.filter((i) => !i.isStaked);
  }, [femaleItem]);

  useEffect(() => {
    if (!selectedPairs[amount].id) return;
    if (hens.length) {
      const henIndex = hens.findIndex(
        (i) => i.id === selectedPairs[amount].hen
      );

      setShowedHen(henIndex);
    }

    if (roosters.length) {
      const roosterIndex = roosters.findIndex(
        (i) => i.id === selectedPairs[amount].rooster
      );
      setShowedRooster(roosterIndex);
    }

    const itemIndex = femaleItem.findIndex(
      (i) => i.id === selectedPairs[amount].item
    );

    setShowedItem(itemIndex);
  }, [selectedPairs, amount, hens, roosters, femaleItem]);

  const handleSelect =
    (type: 'hen' | 'rooster' | 'item', id: string, position: number) => () => {
      if (selectedPairs[position][type] === id)
        return setSelectedPairs((state) => ({
          ...state,
          [position]: {
            ...state[position],
            [type]: '',
          },
        }));
      setSelectedPairs((state) => ({
        ...state,
        [position]: {
          ...state[position],
          [type]: id,
        },
      }));
    };

  const handleStake = async () => {
    await toast.promise(
      () =>
        stakeHenHouse(
          selectedPairs[amount].rooster,
          selectedPairs[amount].hen,
          selectedPairs[amount].item
        ),
      {
        pending: 'Staking is pending',
        success: 'Staking resolved 👌',
        error: 'Staking rejected 🤯',
      }
    );

    setSelectedPairs({
      1: {} as PairType,
      2: {} as PairType,
      3: {} as PairType,
    });
    const res = await getUserHenHouses();

    res.map((i: string[], index) => {
      setSelectedPairs((state) => ({
        ...state,
        [index + 1]: {
          id: i[0],
          rooster: i[1],
          hen: i[2],
          item: i[3],
          createTime: i[4],
        },
      }));
    });
  };

  const getClaimedEggsStats = async (id: string) => {
    const res = await getEggStats(id);
    setClaimEggsArray((state) => [
      ...state,
      { id, quality: res[0], timestamp: res[1] },
    ]);
  };

  const approveClaim = async () => {
    const promise = async () => {
      setIsOpenApprovedClaim(false);
      const res = await claimEggs(selectedPairs[amount].id!);
      const claimedEggs =
        res.events.HenHouseEggs.returnValues.newEggsFromHenHouse;
      claimedEggs.map((i) => getClaimedEggsStats(i));

      const resHenHouse = await getUserHenHouses();

      // eslint-disable-next-line array-callback-return
      resHenHouse.map((i: string[], index) => {
        setSelectedPairs((state) => ({
          ...state,
          [index + 1]: {
            id: i[0],
            rooster: i[1],
            hen: i[2],
            item: i[3],
            createTime: i[4],
          },
        }));
      });
    };
    await toast.promise(promise, {
      pending: 'Claim is pending',
      success: 'Claim resolved 👌',
      error: 'Claim rejected 🤯',
    });
  };

  const handleClaim = async () => {
    const timeLeft = calculateTimeLeft(
      Number(selectedPairs[amount].createTime) * 1000 + 0.5 * 60 * 60 * 1000
    );
    if (Number(timeLeft.minutes) && Number(timeLeft.seconds)) {
      setIsOpenApprovedClaim(true);
      return;
    }
    approveClaim();
  };

  const handleUnstake = async () => {
    await unstakeHenHouse(selectedPairs[amount].id!);
    setSelectedPairs({
      1: {} as PairType,
      2: {} as PairType,
      3: {} as PairType,
    });
    const res = await getUserHenHouses();

    res.map((i: string[], index) => {
      setSelectedPairs((state) => ({
        ...state,
        [index + 1]: {
          id: i[0],
          rooster: i[1],
          hen: i[2],
          item: i[3],
          createTime: i[4],
        },
      }));
    });
  };

  const handleClose = () => setClaimEggsArray([]);

  const handleCloseApprove = () => setIsOpenApprovedClaim(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          margin: '20px 10px 10px',
          border: '1px solid #333333',
          padding: '18px 18px 18px 0px',
        }}
      >
        {selectedPairs[amount].id && roosters.length && hens.length ? (
          <Box
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 25px',
            }}
          >
            <SingleSelectCard
              showedItem={showedRooster}
              itemsLength={roosters.length}
              childrenWidth={250}
              isDisabledBtn={Boolean(selectedPairs[amount].id)}
              children={
                <ReviewCardCenterChild
                  title={`Rooster ID #${roosters[showedRooster].id}`}
                  secondBtnTitle={
                    selectedPairs[amount].id ? undefined : 'Select'
                  }
                  secondButtonHandler={handleSelect(
                    'rooster',
                    roosters[showedRooster].id,
                    amount
                  )}
                  isSelected={
                    roosters[showedRooster].id === selectedPairs[amount].rooster
                  }
                  isStaked={roosters[showedRooster].isStaked}
                >
                  <RoosterCard rooster={roosters[showedRooster]} type="arena" />
                </ReviewCardCenterChild>
              }
            />
            <SingleSelectCard
              showedItem={showedHen}
              itemsLength={hens.length}
              childrenWidth={250}
              isDisabledBtn={Boolean(selectedPairs[amount].id)}
              children={
                <ReviewCardCenterChild
                  title={`Hen ID #${hens[showedHen].id}`}
                  secondBtnTitle={
                    selectedPairs[amount].id ? undefined : 'Select'
                  }
                  secondButtonHandler={handleSelect(
                    'hen',
                    hens[showedHen].id,
                    amount
                  )}
                  isSelected={hens[showedHen].id === selectedPairs[amount].hen}
                  isStaked={hens[showedHen].isStaked}
                >
                  <HenCard hen={hens[showedHen]} type="breed" />
                </ReviewCardCenterChild>
              }
            />
            <SingleSelectCard
              showedItem={showedItem}
              itemsLength={femaleItem.length}
              childrenWidth={250}
              isDisabledBtn={Boolean(selectedPairs[amount].id)}
              children={
                selectedPairs[amount].item &&
                femaleItem[showedItem] &&
                showedItem !== -1 ? (
                  <ReviewCardCenterChild
                    title={`Item ID #${femaleItem[showedItem].id}`}
                    secondBtnTitle={
                      selectedPairs[amount].id ? undefined : 'Select'
                    }
                    secondButtonHandler={handleSelect(
                      'item',
                      femaleItem[showedItem].id,
                      amount
                    )}
                    isSelected={
                      femaleItem[showedItem].id === selectedPairs[amount].item
                    }
                    isStaked={femaleItem[showedItem].isStaked}
                  >
                    <ItemCard item={femaleItem[showedItem]} type="breed" />
                  </ReviewCardCenterChild>
                ) : (
                  <EmptyList style={{ width: '250px', minHeight: '240px' }} />
                )
              }
            />
          </Box>
        ) : (
          <>
            {notStackedRoosters.length ? (
              <Carousel disabled={notStackedRoosters.length <= 1}>
                {notStackedRoosters.map((i) => {
                  return (
                    <Fragment key={i.id}>
                      <ReviewCardCenterChild
                        title={`Rooster ID #${i.id}`}
                        secondBtnTitle={
                          selectedPairs[amount].id ? undefined : 'Select'
                        }
                        secondButtonHandler={handleSelect(
                          'rooster',
                          i.id,
                          amount
                        )}
                        isSelected={i.id === selectedPairs[amount].rooster}
                        isStaked={i.isStaked}
                      >
                        <RoosterCard rooster={i} type="arena" />
                      </ReviewCardCenterChild>
                    </Fragment>
                  );
                })}
              </Carousel>
            ) : (
              <EmptyList style={{ width: '250px', minHeight: '240px' }} />
            )}
            {notStackedHens.length ? (
              <Carousel disabled={notStackedHens.length <= 1}>
                {notStackedHens.map((i) => {
                  return (
                    <Fragment key={i.id}>
                      <ReviewCardCenterChild
                        title={`Hen ID #${i.id}`}
                        secondBtnTitle={
                          selectedPairs[amount].id ? undefined : 'Select'
                        }
                        secondButtonHandler={handleSelect('hen', i.id, amount)}
                        isSelected={i.id === selectedPairs[amount].hen}
                        isStaked={i.isStaked}
                      >
                        <HenCard hen={i} type="breed" />
                      </ReviewCardCenterChild>
                    </Fragment>
                  );
                })}
              </Carousel>
            ) : (
              <EmptyList
                style={{
                  width: '250px',
                  minHeight: '240px',
                  marginLeft: '50px',
                }}
              />
            )}
            {notStackedItems.length ? (
              <Carousel disabled={notStackedItems.length <= 1}>
                {notStackedItems.map((i) => {
                  return (
                    <Fragment key={i.id}>
                      <ReviewCardCenterChild
                        title={`Item ID #${i.id}`}
                        secondBtnTitle={
                          selectedPairs[amount].id ? undefined : 'Select'
                        }
                        secondButtonHandler={handleSelect('item', i.id, amount)}
                        isSelected={i.id === selectedPairs[amount].item}
                        isStaked={i.isStaked}
                      >
                        <ItemCard item={i} type="breed" />
                      </ReviewCardCenterChild>
                    </Fragment>
                  );
                })}
              </Carousel>
            ) : (
              <EmptyList
                style={{
                  width: '250px',
                  minHeight: '240px',
                  marginLeft: '50px',
                }}
              />
            )}
          </>
        )}
        <Box
          sx={{
            flex: '1 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {selectedPairs[amount].id ? (
            <BreedingTimer
              amount={amount}
              selectedPairs={selectedPairs}
              handleClaim={handleClaim}
              handleUnstake={handleUnstake}
            />
          ) : (
            <Button
              sx={{ width: '50%' }}
              variant="contained"
              disabled={
                !selectedPairs[amount].rooster || !selectedPairs[amount].hen
              }
              onClick={handleStake}
            >
              <Box>
                <FavoriteIcon sx={{ color: '#333333' }} />
                <Typography sx={{ fontSize: '14px' }}>
                  Stake for breding
                </Typography>
              </Box>
            </Button>
          )}
        </Box>
      </Box>
      <Modal
        open={Boolean(claimEggsArray.length)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="modal-modal-title">
          <ClaimEggsModal
            claimEggs={claimEggsArray}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Modal
        open={isOpenApprovedClaim}
        onClose={handleCloseApprove}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="modal-modal-title">
          <Box
            sx={{
              ...modalStyle,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                width: '47%',
                textAlign: 'center',
                marginBottom: '20px',
                fontWeight: '500',
              }}
            >
              If you claim eggs now, you don't receive all three eggs. Do you
              want to proceed?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '80%',
              }}
            >
              <Button onClick={approveClaim}>OK</Button>
              <Button onClick={handleCloseApprove}>CANCEL</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
