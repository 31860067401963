import { Box, Button, Typography } from "@mui/material";
import { EggCard, ReviewCardCenterChild } from "../../components";
import { Egg } from "../../hooks";

export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 615,
  bgcolor: "#565555",
  border: "1px solid #CCCCCC",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

type ClaimEggsModalPropsType = {
  claimEggs: Egg[];
  handleClose: () => void;
};

export const ClaimEggsModal: React.FC<ClaimEggsModalPropsType> = ({
  claimEggs,
  handleClose,
}) => {
  return (
    <Box
      sx={{
        ...modalStyle,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        id="modal-modal-title"
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          fontWeight: "500",
          fontSize: "16px",
        }}
      >
        you received
        {claimEggs.length === 1
          ? ` ${claimEggs.length} egg`
          : ` ${claimEggs.length} eggs`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "40px 0px",
        }}
      >
        {claimEggs.map((egg) => {
          return (
            <Box
              key={egg.id}
              sx={{ flex: `0 1 calc(98%/${claimEggs.length})` }}
            >
              <ReviewCardCenterChild id={egg.id} title={`Egg ID #${egg.id}`}>
                <EggCard egg={egg} type="mini" />
              </ReviewCardCenterChild>
            </Box>
          );
        })}
      </Box>
      <Button sx={{ width: "50%" }} variant="contained" onClick={handleClose}>
        Claim
      </Button>
    </Box>
  );
};
