import { Box, Button, Typography } from "@mui/material";
import EggImg from "../../assets/img/egg.png";
import { Egg } from "../../hooks";

type SingleSelectedEggPropsType = {
  hatchHandler: () => void;
  egg: Egg;
};

export const SingleSelectedEgg: React.FC<SingleSelectedEggPropsType> = ({
  egg,
  hatchHandler,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "-100px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Typography sx={{ fontSize: "24px" }}>Egg ID #{egg.id}</Typography>
        <img src={EggImg} alt="egg" style={{ height: "200px" }} />
        <Typography sx={{ fontSize: "24px" }}>
          Quality: {egg.quality}
        </Typography>
      </Box>

      <Button
        variant="contained"
        sx={{ padding: "6px 90px", marginTop: "10px" }}
        onClick={hatchHandler}
      >
        Hatch
      </Button>
    </Box>
  );
};
