import { Box, Typography } from "@mui/material";
import EggImg from "../../assets/img/hen.png";
import moment from "moment";
import { ChickenType } from "../../hooks";

type HenCardPropsType = {
  hen: ChickenType;
  type?: "hens" | "breed";
};

export const HenCard: React.FC<HenCardPropsType> = ({
  hen,
  type = "hens",
}) => {
  if (!hen) return <div>error</div>;
  return (
    <Box sx={{ display: "flex" }}>
      <img
        src={EggImg}
        alt="rooster"
        style={{
          width: "60%",
          objectFit: "cover",
          marginLeft: type === "hens" ? "-20px" : "",
        }}
      />
      <Box
        sx={{
          borderLeft: "1px solid #333333",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: type === "hens" ? "32px" : "18px" }}>
          Fertility
        </Typography>
        <Typography
          sx={{
            fontSize: type === "hens" ? "32px" : "18px",
            paddingBottom: type === "hens" ? "40px" : "0px",
          }}
        >
          {hen.fertility}
        </Typography>
        {type === "hens" && (
          <Typography sx={{ fontSize: "16px", borderTop: "1px solid white" }}>
            Birthday:
            <br />
            {moment(Number(hen.birthTimestamp) * 1000).format(
              "DD.MM.yyyy (ddd)"
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
