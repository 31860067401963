import { Header, NotAuthContainers, SideBar } from "./containers";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  BattleArena,
  Breeding,
  Hens,
  Dashboard,
  Deposit,
  Eggs,
  Hatching,
  Items,
  Marketplace,
  Roosters,
  Spells,
  WitchcraftDoctor,
} from "./pages";
import { useMetaMask } from "metamask-react";
import { createTheme, ThemeProvider } from "@mui/material";
import { BalanceContext } from "./context";
import { useBalance } from "./hooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MetamaskSvg } from "./components";
import s from "./App.module.css";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const App = () => {
  const { status } = useMetaMask();
  const {
    isLoadingDeposit,
    matic,
    token,
    deposit,
    getDeposit,
    getWalletBalance,
  } = useBalance();

  if (status === "initializing") return null;
  if (status === "unavailable")
    return (
      <div className={s.unavailableWrapper}>
        <div className={s.unavailableContent}>
          Please, install Metamask Wallet
          <span>
            <MetamaskSvg />
          </span>
          !
        </div>
      </div>
    );
  if (status === "notConnected" || status === "connecting")
    return <NotAuthContainers />;

  return (
    <BalanceContext.Provider
      value={{
        isLoadingDeposit,
        matic,
        token,
        deposit,
        getDeposit,
        getWalletBalance,
      }}
    >
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <ToastContainer />
          <div className={s.wrapper}>
            <Header />
            <div className={s.content}>
              <SideBar />
              <div className={s.centerContent}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="deposit" element={<Deposit />} />
                  <Route path="marketplace" element={<Marketplace />} />
                  <Route path="roosters" element={<Roosters />} />
                  <Route path="hens" element={<Hens />} />
                  <Route path="eggs" element={<Eggs />} />
                  <Route path="items" element={<Items />} />
                  <Route path="spells" element={<Spells />} />
                  <Route path="hatching" element={<Hatching />} />
                  <Route path="breeding" element={<Breeding />} />
                  <Route path="arena" element={<BattleArena />} />
                  <Route path="doctor" element={<WitchcraftDoctor />} />
                </Routes>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </BalanceContext.Provider>
  );
};
