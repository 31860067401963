import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { useContract } from "./useContract";

export type Spells = {
  id: string;
  selector: string;
  bonus: string;
  requiredWins: string;
  isStaked: boolean;
};

export const useSpells = () => {
  const { account } = useMetaMask();
  const { spellsContract } = useContract();

  const [spells, setSpells] = useState<Spells[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const getSpells = async () => {
    setLoading(true);
    try {
      const spells = await spellsContract.methods
        .getUserNftsWithParams(account)
        .call();

      const objArray = spells.map((i) => {
        return {
          id: i[0],
          selector: i[1],
          bonus: i[2],
          requiredWins: i[3],
          isStaked: Boolean(Number(i[4])),
        };
      });

      setSpells(objArray);
    } catch (error) {
      console.log("getUserNftsWithParams: ", error);
    }
    setLoading(false);
  };

  const getSpellStats = async (spellId: string) => {
    try {
      const res = await spellsContract.methods.getStats(+spellId).call();

      return res;
    } catch (error) {
      console.log("getSpellStats error:", error);
    }
  };

  useEffect(() => {
    getSpells();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const burnSpells = async (ids: string[]) => {
    try {
      const res = await spellsContract.methods
        .burnMany(ids)
        .send({ from: account });
      return res;
    } catch (error) {
      console.log("burnItems: ", error);
    }
  };

  return {
    spells,
    isLoading,
    getSpellStats,
    burnSpells,
    setSpells,
  };
};
