import { Card, Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { EggCard, ReviewCardCenterChild } from "../../components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Dispatch, SetStateAction } from "react";
import { Egg } from "../../hooks";

type MultiCarouselPropsType = {
  eggs: Egg[];
  setSelectedEggs: Dispatch<SetStateAction<Egg[]>>;
  selectedEggs: Egg[];
};

export const MultiCarousel: React.FC<MultiCarouselPropsType> = ({
  eggs,
  selectedEggs,
  setSelectedEggs,
}) => {
  const hatchHandler = (egg: Egg) => () => setSelectedEggs([egg]);

  const selectHandler = (egg: Egg) => () => {
    if (selectedEggs.filter((i) => i.id === egg.id).length) {
      const newArr = selectedEggs.filter((i) => i.id !== egg.id);
      setSelectedEggs(newArr);
      return;
    }
    setSelectedEggs((state) => [...state, egg]);
  };
  const sliderItems: number = eggs.length > 6 ? 6 : eggs.length;
  const items: Array<any> = [];

  for (let i = 0; i < eggs.length; i += sliderItems) {
    if (i % sliderItems === 0) {
      items.push(
        <Card key={i.toString()} sx={{ background: "transparent" }}>
          <Grid container spacing={1} sx={{ background: "transparent" }}>
            {eggs.slice(i, i + sliderItems).map((egg) => {
              return (
                <Grid item xs={2} key={egg.id}>
                  <ReviewCardCenterChild
                    id={egg.id}
                    title={`Egg ID #${egg.id}`}
                    titleBtn="Hatch"
                    secondBtnTitle="Select"
                    buttonHandler={hatchHandler(egg)}
                    secondButtonHandler={selectHandler(egg)}
                    isSelected={Boolean(
                      selectedEggs.filter((i) => i.id === egg.id).length
                    )}
                  >
                    <EggCard egg={egg} type="mini" />
                  </ReviewCardCenterChild>
                </Grid>
              );
            })}
          </Grid>
        </Card>
      );
    }
  }

  return (
    <Carousel
      animation="slide"
      autoPlay={false}
      cycleNavigation
      sx={{ background: "transparent" }}
      NextIcon={<ChevronRightIcon />}
      PrevIcon={<ChevronLeftIcon />}
    >
      {items}
    </Carousel>
  );
};
