import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import BrokenEggImg from "../../assets/img/brokenEgg.png";
import {
  HenCard,
  ItemCard,
  ReviewCardCenterChild,
  RoosterCard,
  SpellCard,
} from "../../components";
import { getItemValue, getSpellValue } from "../../helpers";
import { HatchedEgg, itemType } from "../../hooks";

type HatchingResultPropsType = {
  hatchedEggs: HatchedEgg[];
};

const getDetailRow = (type: string, item: string[]) => {
  if (type === "0") {
    return `A:${item[0]} D:${item[1]} H:${item[2]} S:${item[3]} L:${item[4]}`;
  } else if (type === "1") {
    return `Fertility: ${item[0]}`;
  } else if (type === "2") {
    return getItemValue(itemType[item[0]], Number(item[1]))?.name;
  } else if (type === "3") {
    return getSpellValue(Number(item[0]))?.name;
  }
};

export const HatchingResult: React.FC<HatchingResultPropsType> = ({
  hatchedEggs,
}) => {
  const typeCard = { 0: "Rooster", 1: "Hen", 2: "Item", 3: "Spell" };

  const childrenComponent = {
    0: (
      <RoosterCard
        rooster={{
          id: hatchedEggs[0].id[0],
          type: typeCard[hatchedEggs[0].type],
          attack: hatchedEggs[0].stats[0],
          defence: hatchedEggs[0].stats[1],
          health: hatchedEggs[0].stats[2],
          speed: hatchedEggs[0].stats[3],
          luck: hatchedEggs[0].stats[4],
          wins: hatchedEggs[0].stats[5],
          birthTimestamp: hatchedEggs[0].stats[6],
        }}
      />
    ),
    1: (
      <HenCard
        hen={{
          id: hatchedEggs[0].id[0],
          type: typeCard[hatchedEggs[0].type],
          fertility: hatchedEggs[0].stats[0],
          defence: hatchedEggs[0].stats[1],
          health: hatchedEggs[0].stats[2],
          speed: hatchedEggs[0].stats[3],
          luck: hatchedEggs[0].stats[4],
          wins: hatchedEggs[0].stats[5],
          birthTimestamp: hatchedEggs[0].stats[6],
        }}
      />
    ),
    2: (
      <ItemCard
        item={{
          id: hatchedEggs[0].id[0],
          type: itemType[hatchedEggs[0].stats[0]],
          selector: hatchedEggs[0].stats[1],
          bonus: hatchedEggs[0].stats[2],
          zodiac: hatchedEggs[0].stats[3],
          requiredWins: hatchedEggs[0].stats[4],
          vampiric: hatchedEggs[0].stats[5],
          isStaked: false,
        }}
      />
    ),
    3: (
      <SpellCard
        spell={{
          id: hatchedEggs[0].id[0],
          selector: hatchedEggs[0].stats[0],
          bonus: hatchedEggs[0].stats[1],
          requiredWins: hatchedEggs[0].stats[2],
          isStaked: false,
        }}
      />
    ),
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={BrokenEggImg}
          alt="eggs"
          style={{ height: "200px", marginLeft: "100px" }}
        />
      </Box>
      <Box sx={{ marginRight: "100px" }}>
        <Typography
          sx={{ fontSize: "24px", textAlign: "center", marginBottom: "10px" }}
        >
          Hatching result
        </Typography>
        {hatchedEggs.length === 1 ? (
          <Box sx={{ width: "400px" }}>
            <ReviewCardCenterChild
              title={`${typeCard[hatchedEggs[0].type]} ID #${
                hatchedEggs[0].id
              }`}
              titleBtn={`Go to ${typeCard[hatchedEggs[0].type]}s`}
            >
              {childrenComponent[hatchedEggs[0].type]}
            </ReviewCardCenterChild>
          </Box>
        ) : (
          <TableContainer sx={{ maxWidth: 600, marginRight: "30px" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #575757" }} align="left">
                    №
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #575757" }} align="left">
                    ID
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #575757" }} align="left">
                    Result
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #575757" }} align="left">
                    Details
                  </TableCell>
                  <TableCell align="left" sx={{ border: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hatchedEggs.map((row: HatchedEgg, index) => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{ border: "1px solid #575757" }}
                      align="left"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid #575757" }}
                      align="left"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid #575757" }}
                      align="left"
                    >
                      {typeCard[row.type]}
                    </TableCell>
                    <TableCell
                      sx={{ border: "1px solid #575757" }}
                      align="left"
                    >
                      {getDetailRow(row.type, row.stats)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};
