import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { useChickens } from "./useChickens";
import { useContract } from "./useContract";
import { useItems } from "./useItems";
import { useSpells } from "./useSpells";

export type HatchedEgg = {
  id: string;
  type: string;
  stats: string[];
};
export type Egg = {
  id: string;
  quality: string;
  timestamp: string;
};

export const useEggs = () => {
  const { account } = useMetaMask();
  const { eggsContract } = useContract();
  const { getChickenStats } = useChickens();
  const { getItemStats } = useItems();
  const { getSpellStats } = useSpells();

  const [eggs, setEggs] = useState<Egg[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hatchedEggs, setHatchedEggs] = useState<HatchedEgg[]>([]);

  const getUserEggs = async () => {
    setLoading(true);
    const eggsList = await eggsContract.methods
      .getUserNftsWithParams(account)
      .call();

    const list = eggsList.map((i) => {
      return {
        id: i[0],
        quality: i[1],
        timestamp: i[2],
      };
    });

    setEggs(list);
    setLoading(false);
  };

  const getEggStats: (id: string) => Promise<string[]> = async (id: string) => {
    const eggStats = await eggsContract.methods.getStats(id).call();
    return eggStats;
  };

  const buyEgg = async (quantity: number) => {
    try {
      await eggsContract.methods.mintMany(quantity).send({ from: account });
    } catch (error) {
      console.log(error);
    }
    getUserEggs();
  };

  const hatchEggs = async (eggs: string[]) => {
    let res;
    try {
      res = await eggsContract.methods.hatchMany(eggs).send({ from: account });
    } catch (e) {
      console.log("hatchMany error: ", e);
      
    }
    if (!res) return;

    if (eggs.length === 1) {
      let stats;
      const type = res.events.HatchMany.returnValues.newType[0];
      if (type === "0" || type === "1") {
        stats = await getChickenStats(res.events.HatchMany.returnValues.newId);
      } else if (type === "2") {
        stats = await getItemStats(res.events.HatchMany.returnValues.newId);
      } else if (type === "3") {
        stats = await getSpellStats(res.events.HatchMany.returnValues.newId);
      }

      setHatchedEggs((state) => [
        ...state,
        {
          id: res.events.HatchMany.returnValues.newId,
          type: res.events.HatchMany.returnValues.newType,
          stats,
        },
      ]);
    } else if (eggs.length > 1) {
      for (let chicken of res.events.EggHatched) {
        const getData = async () => {
          let stats;
          const type = chicken.returnValues.newType[0];
          if (type === "0" || type === "1") {
            stats = await getChickenStats(chicken.returnValues.newId);
          } else if (type === "2") {
            stats = await getItemStats(chicken.returnValues.newId);
          } else if (type === "3") {
            stats = await getSpellStats(chicken.returnValues.newId);
          }
          setHatchedEggs((state) => [
            ...state,
            {
              id: chicken.returnValues.newId,
              type: chicken.returnValues.newType,
              stats,
            },
          ]);
        };
        getData();
      }
    }
    getUserEggs();
  };

  useEffect(() => {
    getUserEggs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    eggs,
    hatchedEggs,
    buyEgg,
    getEggStats,
    hatchEggs,
    getUserEggs,
  };
};
