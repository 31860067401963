import { Box, Typography } from "@mui/material";
import EggImg from "../../assets/img/egg.png";
import { Egg } from "../../hooks";

type EggCardPropsType = {
  egg: Egg;
  type?: "mini" | "big";
};

export const EggCard: React.FC<EggCardPropsType> = ({ egg, type = "big" }) => {
  return (
    <Box sx={{ display: "flex", height: type === "big" ? "" : "72px" }}>
      <img
        src={EggImg}
        alt="rooster"
        style={{
          width: "50%",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          borderLeft: "1px solid #333333",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: type === "big" ? "36px" : "16px" }}>
          Quality:
        </Typography>
        <Typography sx={{ fontSize: type === "big" ? "36px" : "16px" }}>
          {egg.quality}
        </Typography>
      </Box>
    </Box>
  );
};
