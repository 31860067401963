import { Box, Typography } from "@mui/material";
import RoosterImg from "../../assets/img/rooster.png";
import moment from "moment";

const characteristics = [
  { name: "Attack" },
  { name: "Defence" },
  { name: "Speed" },
  { name: "Luck" },
  { name: "Health" },
  { name: "Zodiac" },
];

type RoosterCharacteristicsPropsType = {
  selectedRooster: {
    attack: string;
    defence: string;
    speed: string;
    luck: string;
    health: string;
    birthTimestamp: string;
  };
};

export const RoosterCharacteristics: React.FC<
  RoosterCharacteristicsPropsType
> = ({ selectedRooster }) => {
  return (
    <Box
      sx={{
        display: "flex",
        background: "rgba(217, 217, 217, 0.05)",
        padding: "0px 10px 20px",
        border: "1px solid #CCCCCC",
      }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {characteristics.map((i) => {
          return (
            <Box
              key={i.name}
              sx={{
                flex: "0 0 50%",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>{i.name}</Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  padding: "4px",
                  border: "1px solid #CCCCCC",
                  width: "40px",
                  height: "40px",
                  borderRadius: "10px",
                  background: "rgba(217, 217, 217, 0.05)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "5px",
                }}
              >
                {i.name === "Zodiac"
                  ? selectedRooster
                    ? moment(
                        Number(selectedRooster.birthTimestamp) * 1000
                      ).day()
                    : ""
                  : selectedRooster
                  ? selectedRooster[i.name.toLowerCase()]
                  : ""}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <img
        src={RoosterImg}
        alt="rooster"
        style={{
          width: "50%",
          objectFit: "cover",
          marginTop: "10px",
        }}
      />
    </Box>
  );
};
