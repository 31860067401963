import { Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { useState } from "react";
import {
  BurnSvg,
  ColorButton,
  HenCard,
  ReviewCardCenterChild,
} from "../../components";
import { BurnModal } from "../../containers";
import { useChickens } from "../../hooks";

const gridStyles = {
  paddingBottom: 2,
  marginTop: 2,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
};

export const Hens = () => {
  const { hens, burnChicken, setHens } = useChickens();

  const [isShowedBurnModal, setIsShowedBurnModal] = useState<boolean>(false);

  const toogleBurnModal = (val: boolean) => () => setIsShowedBurnModal(val);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "20px",
            marginTop: "32px",
            marginRight: "72px",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>
            Hens | Total: {hens.length}
          </Typography>
          <ColorButton onClick={toogleBurnModal(true)}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "35px",
              }}
            >
              <Typography
                sx={{
                  borderRight: "1.5px solid #CCCCCC",
                  paddingRight: "6px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "15px",
                }}
              >
                burn
              </Typography>
              <Box
                sx={{
                  paddingLeft: "6px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BurnSvg />
              </Box>
            </Box>
          </ColorButton>
        </Box>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          columns={16}
          sx={gridStyles}
        >
          {hens.map((i, index) => (
            <Grid item xs={5} key={i.id}>
              <ReviewCardCenterChild
                title={`Hen ID #${i.id}`}
                titleBtn="Go breed!"
                id={String(index)}
                link="/breeding"
                isStaked={i.isStaked}
              >
                <HenCard hen={i} />
              </ReviewCardCenterChild>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Modal
        open={isShowedBurnModal}
        onClose={toogleBurnModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="modal-modal-title">
          <BurnModal
            items={hens.filter((i) => !i.isStaked)}
            type="Hen"
            closeModal={toogleBurnModal(false)}
            burnItems={burnChicken}
            changeItemsArray={setHens}
          />
        </Box>
      </Modal>
    </>
  );
};
