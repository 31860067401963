import { Box, Button, Typography } from "@mui/material";
import { ItemCard, ReviewCardCenterChild } from "../../components";
import { Item } from "../../hooks";
import { modalStyle } from "../ClaimEggsModal";

type CraftedItemModalPropsType = {
  item: Item;
  handleClose: () => void
};

export const CraftedItemModal: React.FC<CraftedItemModalPropsType> = ({
  item,
  handleClose,
}) => {
  return (
    <Box
      sx={{
        ...modalStyle,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        id="modal-modal-title"
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          fontWeight: "500",
          fontSize: "16px",
          marginBottom: "12px",
        }}
      >
        you received 1 item
      </Typography>
      <ReviewCardCenterChild title={`Item ID #${item.id}`}>
        <ItemCard item={item} type="arena" />
      </ReviewCardCenterChild>
      <Button sx={{ width: "50%", marginTop: '24px' }} variant="contained" onClick={handleClose}>
        Claim
      </Button>
    </Box>
  );
};
