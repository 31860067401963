import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReviewCard } from "../../components";
import { useBalanceContext } from "../../context";
import {
  useChickens,
  useEggs,
  useItems,
  useSpells,
} from "../../hooks";

const gridStyles = {
  paddingBottom: 2,
  paddingRight: 2,
  marginTop: 2,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
};

export const Dashboard = () => {
  const { eggs, isLoading: isLoadingEggs } = useEggs();
  const { items, isLoading: isLoadingItems } = useItems();
  const { spells, isLoading: isLoadingSpells } = useSpells();
  const { roosters, hens, isLoading } = useChickens();
  const { deposit, isLoadingDeposit } = useBalanceContext();

  const dashboardCard = [
    {
      title: "Deposit",
      centerContent: `${deposit} RHC`,
      titleFirstBtn: "Make deposit",
      firstLinkTo: "/deposit",
      isLoading: isLoadingDeposit,
    },
    {
      title: "Eggs",
      centerContent: eggs.length,
      titleFirstBtn: "Marketplace",
      titleSecondBtn: "go to Hatching",
      firstLinkTo: "/marketplace",
      seconsdLinkTo: "/hatching",
      isLoading: isLoadingEggs,
    },
    {
      title: "Items",
      centerContent: items.length,
      titleFirstBtn: "See all",
      isLoading: isLoadingItems,
      firstLinkTo: "/items",
    },
    {
      title: "Roosters",
      centerContent: roosters.length,
      titleFirstBtn: "see all roosters",
      titleSecondBtn: "go fight",
      isLoading,
      firstLinkTo: "/roosters",
      seconsdLinkTo: "/arena",
    },
    {
      title: "Hens",
      centerContent: hens.length,
      titleFirstBtn: "Breeding",
      isLoading,
      firstLinkTo: "/breeding",
    },
    {
      title: "Spells",
      centerContent: spells.length,
      titleFirstBtn: "See all",
      isLoading: isLoadingSpells,
      firstLinkTo: "/spells",
    },
  ];

  return (
    <Box>
      <Typography
        sx={{ fontSize: "20px", marginLeft: "20px", marginTop: "32px" }}
      >
        Dashboard
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        columns={16}
        sx={gridStyles}
      >
        {dashboardCard.map((i, index) => (
          <Grid item xs={5} key={index}>
            <ReviewCard
              title={i.title}
              centerContent={i.centerContent}
              titleFirstBtn={i.titleFirstBtn}
              titleSecondBtn={i.titleSecondBtn}
              isLoading={i.isLoading}
              firstLinkTo={i.firstLinkTo}
              seconsdLinkTo={i.seconsdLinkTo}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
