import { useMetaMask } from 'metamask-react';
import { useEffect, useState } from 'react';
import { useContract } from './useContract';

export type ChickenType = {
  id: string;
  type: 'Rooster' | 'Hen' | 'Bones' | 'Zombie';
  attack?: string;
  fertility?: string;
  defence: string;
  health: string;
  speed: string;
  luck: string;
  wins: string;
  birthTimestamp: string;
  isStaked?: boolean;
};

export const useChickens = () => {
  const { account } = useMetaMask();
  const { chickensContract } = useContract();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [roosters, setRoosters] = useState<ChickenType[]>([]);
  const [hens, setHens] = useState<ChickenType[]>([]);

  const getUserChickens = async () => {
    setLoading(true);
    const chickensList = await chickensContract.methods
      .getUserNftsWithParams(account)
      .call();

    const types = {
      0: 'Rooster',
      1: 'Hen',
      2: 'Bones',
      3: 'Zombie',
    };

    const arr: ChickenType[] = chickensList.map((i) => {
      return {
        id: i[0],
        type: types[i[1]],
        [types[i[1]] === 'Hen' ? 'fertility' : 'attack']: i[2],
        defence: i[3],
        health: i[4],
        speed: i[5],
        luck: i[6],
        wins: i[7],
        birthTimestamp: i[8],
        isStaked: Boolean(Number(i[9])),
      };
    });

    setHens(arr.filter((i) => i.type === 'Hen'));
    setRoosters(arr.filter((i) => i.type === 'Rooster'));
    setLoading(false);
  };

  useEffect(() => {
    getUserChickens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChickenStats = async (roosterId: string) => {
    try {
      const res = await chickensContract.methods.getStats(+roosterId).call();

      return res;
    } catch (error) {
      console.log('getChickenStats error:', error);
    }
  };

  const getUserHenHouses = async () => {
    try {
      const res = await chickensContract.methods
        .getUserHenHousesWithParams(account)
        .call();
      return res;
    } catch (error) {
      console.log('getUserHenHousesWithParams:', error);
    }
  };

  const stakeHenHouse = async (
    roosterId: string,
    henId: string,
    itemId?: string
  ) => {
    try {
      await chickensContract.methods
        .stakeHenHouse(roosterId, henId, itemId || '0')
        .send({ from: account });
    } catch (error) {
      console.log('stakeHenHouse:', error);
    }
  };

  const checkStakeCycle = async (id: string) => {
    const res = await chickensContract.methods.checkStakeCycle(id).call();
    return Number(res);
  };

  const claimEggs = async (id: string) => {
    try {
      const res = await chickensContract.methods
        .updateHenHouse(id)
        .send({ from: account });
      return res;
    } catch (error) {
      console.log('updateHenHouse: ', error);
    }
  };

  const unstakeHenHouse = async (id: string) => {
    try {
      await chickensContract.methods
        .releaseHenHouse(id)
        .send({ from: account });
    } catch (error) {
      console.log('unstakeHenHouse: ', error);
    }
  };

  const burnChicken = async (ids: string[]) => {
    try {
      const res = await chickensContract.methods
        .burnMany(ids)
        .send({ from: account });

      return res;
    } catch (error) {
      console.log('burnChicken: ', error);
    }
  };

  return {
    hens,
    roosters,
    isLoading,
    setRoosters,
    getChickenStats,
    stakeHenHouse,
    getUserHenHouses,
    checkStakeCycle,
    claimEggs,
    unstakeHenHouse,
    burnChicken,
    setHens,
    getUserChickens,
  };
};
