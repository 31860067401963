import Web3 from "web3/dist/web3.min.js";
import { CONTRACT_ABI, CONTRACT_ADDRESS } from "../contracts/config";
import { connectContract } from "./connectContract";

export const sendToken = async (to: string, amount: string) => {
  if (!process.env.REACT_APP_POLYGON_TESTNET_RPC) {
    console.log("add REACT_APP_POLYGON_TESTNET_RPC in .env");
    return;
  }
  const web3 = new Web3(process.env.REACT_APP_POLYGON_TESTNET_RPC);
  const contract = connectContract(web3, CONTRACT_ABI, CONTRACT_ADDRESS);
  try {
    if (!process.env.REACT_APP_METAMASK_PRIVATE_KEY) {
      console.log("add REACT_APP_METAMASK_PRIVATE_KEY in .env");
      return;
    }
    const bankAccount = web3.eth.accounts.privateKeyToAccount(
      process.env.REACT_APP_METAMASK_PRIVATE_KEY
    );

    // pass the private key of the transaction sender to your local web3 instance
    web3.eth.accounts.wallet.add(
      `${process.env.REACT_APP_METAMASK_PRIVATE_KEY}`
    );

    const nonce = await web3.eth.getTransactionCount(bankAccount.address);

    const gasPrice = (+(await web3.eth.getGasPrice()) * 1.4).toFixed();

    await contract.methods
      .transfer(to, amount)
      .send({ from: bankAccount.address, nonce, gasLimit: 100000, gasPrice });
  } catch (error) {
    console.log("sendToken error:", error);
  }
};
