import Web3 from "web3/dist/web3.min.js";

export const sendMatic = async (to: string, value: string) => {
  if (!process.env.REACT_APP_POLYGON_TESTNET_RPC) {
    console.log("add REACT_APP_POLYGON_TESTNET_RPC in .env");
    return;
  }
  const web3 = new Web3(process.env.REACT_APP_POLYGON_TESTNET_RPC);
  if (!process.env.REACT_APP_METAMASK_PRIVATE_KEY) {
    console.log("add REACT_APP_METAMASK_PRIVATE_KEY in .env");
    return;
  }
  const account = web3.eth.accounts.privateKeyToAccount(
    process.env.REACT_APP_METAMASK_PRIVATE_KEY
  );

  const nonce = await web3.eth.getTransactionCount(account.address);

  const transaction = {
    to,
    value,
    nonce,
    gas: 1000000,
  };

  const signedTx = await web3.eth.accounts.signTransaction(
    transaction,
    process.env.REACT_APP_METAMASK_PRIVATE_KEY
  );

  await web3.eth.sendSignedTransaction(
    signedTx.rawTransaction as string,
    function (error, hash) {
      if (!error) {
        console.log("The hash of your transaction is: ", hash);
      } else {
        console.log(error);
      }
    }
  );
};
