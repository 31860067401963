import { Box } from '@mui/material';
import { Fragment } from 'react';
import {
  Carousel,
  HenCard,
  ItemCard,
  ReviewCardCenterChild,
  RoosterCard,
} from '../../components';
import { ChickenType, Item } from '../../hooks';

type SelectItemPropsType = {
  items: Item[] | ChickenType[];
  selectedItemId: string;
  handleSelect: (type: string, id: string) => () => void;
  type: string;
};

export const SelectItem: React.FC<SelectItemPropsType> = ({
  type,
  items,
  selectedItemId,
  handleSelect,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {items.length ? (
        <Carousel disabled={items.length <= 1}>
          {items.map((i) => {
            return (
              <Fragment key={i.id}>
                <ReviewCardCenterChild
                  title={`${
                    type === 'armor' || type === 'weapon' || type === 'artefact'
                      ? 'Item'
                      : type === 'rooster'
                      ? 'Rooster'
                      : 'Hen'
                  } ID #${i.id}`}
                  secondBtnTitle="Select"
                  secondButtonHandler={handleSelect(type, i.id)}
                  isSelected={i.id === selectedItemId}
                  isStaked={i.isStaked}
                >
                  {(type === 'armor' ||
                    type === 'weapon' ||
                    type === 'artefact') && (
                    <ItemCard item={i as Item} type="arena" />
                  )}
                  {type === 'rooster' && (
                    <RoosterCard rooster={i as ChickenType} type="arena" />
                  )}
                  {type === 'hen' && (
                    <HenCard hen={i as ChickenType} type="breed" />
                  )}
                </ReviewCardCenterChild>
              </Fragment>
            );
          })}
        </Carousel>
      ) : (
        <Box
          sx={{
            width: '250px',
            minHeight: '200px',
            border: '1px dashed rgb(204, 204, 204)',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
          }}
        >
          Empty
        </Box>
      )}
    </Box>
  );
};
