import { Box, Typography } from '@mui/material';
import WeaponImg from '../../assets/img/bazooka.png';
import ArmorImg from '../../assets/img/vest.png';
import FemaleItemImg from '../../assets/img/female.png';
import ArtefactImg from '../../assets/img/clover.png';
import { Item } from '../../hooks';
import { getItemValue } from '../../helpers';

type ItemCardPropsType = {
  item: Item;
  type?: 'items' | 'breed' | 'arena';
};

const imgObj = {
  Weapon: WeaponImg,
  Armor: ArmorImg,
  Artefact: ArtefactImg,
  Female: FemaleItemImg,
  Zombie: FemaleItemImg,
};

export const ItemCard: React.FC<ItemCardPropsType> = ({
  item,
  type = 'items',
}) => {
  const data = getItemValue(item.type, Number(item.selector));

  return (
    <Box sx={{ display: 'flex', height: type === 'items' ? '220px' : '' }}>
      <img
        src={imgObj[item.type]}
        alt="item"
        style={{
          width:
            type === 'items' ? '187px' : type === 'breed' ? '94px' : '90px',
          height:
            type === 'items' ? '192px' : type === 'breed' ? '94px' : '65px',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          borderLeft: '1px solid #333333',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize:
                type === 'arena' ? '10px' : type === 'breed' ? '12px' : '18px',
            }}
          >
            {data?.name}
          </Typography>
          {type === 'items' && (
            <Typography sx={{ fontSize: '15px', textTransform: 'lowercase' }}>
              ({item.type})
            </Typography>
          )}
        </Box>
        <Typography
          sx={{
            fontSize:
              type === 'arena' ? '10px' : type === 'breed' ? '12px' : '18px',
            textAlign: 'left',
            paddingLeft: '3px',
          }}
        >
          {data?.bonus.replace('<value>', item.bonus)}
        </Typography>
        {(type === 'items' || type === 'arena') && (
          <Box>
            <Typography
              sx={{
                fontSize: type === 'arena' ? '12px' : '18px',
                textAlign: 'left',
                paddingLeft: '3px',
              }}
            >
              Required wins:
            </Typography>
            <Typography
              sx={{
                fontSize: type === 'arena' ? '10px' : '18px',
                textAlign: 'left',
                paddingLeft: '3px',
              }}
            >
              {item.requiredWins}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
