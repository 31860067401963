export const getCardValue = (card: string) => {
  if (card === "1")
    return {
      name: "Card Attack +",
      value: "+10% Attack",
    };
  else if (card === "2")
    return {
      name: "Card Defense +",
      value: "+10% Defense",
    };
  else if (card === "3")
    return {
      name: "Card HP +",
      value: "+10% HP",
    };
  else if (card === "4")
    return {
      name: "Card Lucky charms +",
      value: "+10% Lucky charms",
    };
  else if (card === "5")
    return {
      name: "Card Attack -",
      value: "-10% Attack",
    };
  else if (card === "6")
    return {
      name: "Card Defense -",
      value: "-10% Defense",
    };
  else if (card === "7")
    return {
      name: "Card Quicken +",
      value: "+10% Quicken",
    };
  else if (card === "8")
    return {
      name: "Card Sloth -",
      value: "-10% Sloth",
    };
  else if (card === "9")
    return {
      name: "Card Weapon break",
      value: "Remove weapon effect",
    };
  else if (card === "10")
    return {
      name: "Card Armor break",
      value: "Remove armor effect",
    };
  else if (Number(card) >= 110 && Number(card) < 120)
    return {
      name: "Card Zodiac positive",
      value: "All stats +5%",
    };
  else if (Number(card) >= 120 && Number(card) < 130)
    return {
      name: "Card Zodiac negative",
      value: "All stats -5%",
    };
};
