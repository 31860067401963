import { Box, Button, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";

type ActionsBattlePropsType = {
  actions: { [key: string]: "A" | "S" };
  setActions: Dispatch<SetStateAction<{ [key: string]: "A" | "S" }>>;
};

export const ActionsBattle: React.FC<ActionsBattlePropsType> = ({
  actions,
  setActions,
}) => {
  const replaceHandler = (position: number) => () => {
    const newValue = actions[position] === "A" ? "S" : "A";

    setActions((state) => ({
      ...state,
      [position]: newValue,
    }));
  };
  return (
    <Box
      sx={{
        background: "rgba(217, 217, 217, 0.05)",
        padding: "10px",
        border: "1px solid #CCCCCC",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography sx={{ textAlign: "center" }}>Actions</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {[1, 2, 3, 4, 5, 6].map((i) => {
          return (
            <Box
              key={i}
              sx={{
                flex: "0 0 50%",
                marginTop: "20px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ marginRight: "10px" }}>{i}</Typography>
              <Box
                sx={{
                  width: "115px",
                  height: "110px",
                  background: "rgba(217, 217, 217, 0.05)",
                  border: "1px solid #CCCCCC",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Button
                  startIcon={<AutorenewIcon />}
                  sx={{
                    background: "#D9D9D9",
                    borderRadius: "2px",
                    color: "#000000",
                    padding: "0px",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    fontSize: "20px",
                  }}
                  onClick={replaceHandler(i)}
                >
                  {actions[i] === "A" ? "S" : "A"}
                </Button>
                <Typography sx={{ fontSize: "24px" }}>
                  {actions[i] === "A" ? "Attack" : "Spell"}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
