/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { setNumberInputValue } from "../../helpers";

type DepositFormPropsType = {
  value: string;
  errorMsg: string;
  setValue: Dispatch<SetStateAction<string>>;
};

export const DepositForm: React.FC<DepositFormPropsType> = ({
  errorMsg,
  value,
  setValue,
}) => {
  const handleChangeValue = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, notShow, valueFloat } = setNumberInputValue(e.target.value);
    if (isNaN(valueFloat) || notShow) return;
    setValue(value);
  };

  const buttonHandler = (v: string) => () => setValue(v);
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="filled-search"
        label="Enter the deposit amount"
        variant="filled"
        sx={{ color: "white" }}
        value={value}
        onChange={handleChangeValue}
        error={Boolean(errorMsg)}
        helperText={errorMsg}
      />
      <Box sx={{ dispaly: "flex", marginTop: "37px" }}>
        <Button
          variant="contained"
          color="success"
          sx={{ height: "30px", fontSize: "14px" }}
          onClick={buttonHandler("100")}
        >
          +100 RHC
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{ height: "30px", fontSize: "14px", margin: "0px 5px" }}
          onClick={buttonHandler("500")}
        >
          +500 RHC
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{ height: "30px", fontSize: "14px" }}
          onClick={buttonHandler("1000")}
        >
          +1000 RHC
        </Button>
      </Box>
    </Box>
  );
};
